/* START :: Custom variable */
img {max-width: 100%;}
body {
  font-size: 16px;
}
/* START :: Bootstrap variable  */
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1485px,
);


$body-bg:rgba(29, 29, 29, 0.95) !default;
$body-color: #ffffff !default;

$white: #ffffff;
$black: #000000;
$light-green: #5AC594;
$red: #DA3650;
$blue: #143B56;
$secondary:  #ADFF00 !default;
$line-height-base: 1.5;  
$border-grey : rgba(232, 237, 240, 0.5);
$purple: #9B51E0;
$yellow: #F2994A;
$green: #27AE60;
$grey: #E8EDF0;
$dard-blue: #2D9CDB;
$grey20: #333333;
$light-grey: #CAD3D8;
$light-grey1: #FAFAFA;
$dark-green: #296B4D;
$blue-rgba: rgba(45, 156, 219, 0.2);
$purple-rgba: rgba(187, 107, 217, 0.2);
$yellow-rgba: rgba(242, 201, 76, 0.2);
$green-rgba: rgba(111, 207, 151, 0.2);
$lightblack-rgba: rgba(10, 12, 8, 0.5);
$light-cream: #F3EEE6;

$gray90: #E5E5E5;


$font-size-root: 16px !default;

$font-family-sans-serif : 'Poppins', sans-serif;

//font size
$px175: 10.9375rem;
$px100: 6.25rem;
$px95: 5.9375rem;
$px82: 5.125rem;
$px74: 4.625rem;
$px72: 4.5rem;
$px64: 4rem;
$px62: 3.875rem;
$px61: 3.813rem;
$px60: 3.75rem;
$px59: 3.688rem;
$px58: 3.625rem;
$px57: 3.563rem;
$px56: 3.5rem;
$px55: 3.4375rem;
$px54: 3.375rem;
$px53: 3.313rem;
$px52: 3.25rem;
$px51: 3.188rem;
$px50: 3.125rem;
$px49: 3.063rem;
$px48: 3rem;
$px47: 2.938rem;
$px46: 2.875rem;
$px45: 2.8125rem;
$px44: 2.75rem;
$px43: 2.688rem;
$px42: 2.625rem;
$px41: 2.563rem;
$px40: 2.5rem;
$px39: 2.438rem;
$px38: 2.375rem;
$px37: 2.313rem;
$px36: 2.25rem;
$px35: 2.1875rem;
$px34: 2.125rem;
$px33: 2.063rem;
$px32: 2rem;
$px31: 1.938rem;
$px30: 1.875rem;
$px29: 1.813rem;
$px28: 1.75rem;
$px27: 1.6875rem;
$px26: 1.625rem;
$px25: 1.5625rem;
$px24: 1.5rem;
$px23: 1.438rem;
$px22: 1.375rem;
$px21: 1.3125rem;
$px20: 1.25rem;
$px19: 1.1875rem;
$px18: 1.125rem;
$px17: 1.0625rem;
$px16: 1rem;
$px15: 0.9375rem;
$px14: 0.875rem;
$px13: 0.8125rem;
$px12: 0.75rem;
$px11: 0.688rem;
$px10: 0.625rem;

$h1-font-size: 40px;
$h2-font-size: 36px; 
$h3-font-size: 32px;
$h4-font-size: 30px;
$h5-font-size: 20px;
$h6-font-size: 18px;

$headings-margin-bottom: 10px;
$headings-font-weight : 900;
$headings-font-style: normal;
$headings-color:#2B2E34;
$headings-line-height: 1.20;

$container-padding-x: 15px !default;
$paragraph-margin-bottom: 25px !default;

$btn-font-weight : 700;
$btn-padding-y:  0;
$btn-padding-x: 15px;
$btn-border-width: 0px;
$btn-focus-width: 0px;

$display-inline: inline-block;
$align-middle: middle;


/*END :: Bootstrap variable*/





