*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body { font-family: 'DM Sans', sans-serif; font-weight: 400; margin: 0; background-color: #F3F3F3; color: #040129; display: flex; flex-direction: column; min-height: 100vh;}
p{
	margin: 0;
	font-size: 17px;
	line-height: 30px;
    font-weight: 500;
}
.container-fluid {
    max-width: 90%;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}
.container {
    max-width: 1485px;
    width: 100%;
    margin: auto;
    padding: 0 15px;
}
a{
	color: #0A0C08;
	text-decoration: none;
	transition: 0.3s all ease-in-out;
}
a:hover{
	color: #FF8001;
	transition: 0.3s all ease-in-out;
	text-decoration: none;
}
a:focus-visible{
	outline-offset: none;     
	outline: none;
}
h1{
	margin: 0;
	font-size: 50px;
	line-height: 64px;
	letter-spacing: -2.1px;
	font-family: "AktivGroteskEx-XBold", sans-serif;
}
h2{
	font-size: 40px;
	margin: 0;
}
h3{
	margin: 0;
	font-size: 29px;
}
h4{
	margin: 0;
	font-size: 22px;
	line-height: 1.25;
}
h5{
	font-size: 20px;
}
h6{
	font-size: 18px;
}
.clearfix:before, .clearfix:after { content: ''; display: table; }
.clearfix:after { clear: both; }
.header .container, .industry_sec .container, .cust_breadcrumb_sec .container {
	max-width: 1560px;
}
.header{
	background: url(../images/header-bg.png) #F3F3F3 no-repeat;
	background-size: cover;
	background-position: center bottom -15px;
	padding: 15px 0px;
	position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 999;
	transition: all 0.5s;
	
.header_main{
	.logo{
		max-width: 	249px;
		
	a{
		display: block;
		img{
			width: 100%;
		}
	}
	}
	
.navbar{
	
	.nav-top{
		margin-bottom: 23px;
		ul{
			display: flex;
			margin: 0;
			padding: 0;
			align-items: center;
			justify-content: flex-end;
		li{
			font-weight: 500;
			font-size: 16px;
			line-height: 21px;
			display: $display-inline;
			margin-right: 29px;
			position: relative;
			letter-spacing: 0.49px;
		a{
			color: #040129;
			position: relative;
			
		&:hover{
			color: #F240D2;
		}
		}
		
		&:last-child{ margin-right: 0px; }
		}
		}
	
	}
	
	.nav-btm{
		ul{
			display: flex;
			margin: 0;
			padding: 0;
			align-items: center;
		li{
			font-weight: 700;
			font-size: 22px;
			display: $display-inline;
			margin-right: 43px;
			position: relative;
			letter-spacing: -0.92px;
			font-family: 'AktivGroteskEx-XBold', sans-serif;
		a{
			color: #040129;
			position: relative;
			
		&:hover{
			color: #040129;
		}
		}
		
		&:last-child{ margin-right: 0px; }
		}
		}
	
	}
}
}
}
.main_menu{
	width: calc( 100% - 249px);
	padding-left: 53px;
}
.header.sticky{
	-webkit-animation: smoothScroll .5s forwards;
    -moz-animation: smoothScroll 1s forwards;
    -ms-animation: smoothScroll 1s forwards;
    -o-animation: smoothScroll 1s forwards;
    animation: smoothScroll 1s forwards;
}
@-webkit-keyframes smoothScroll { 0% { transform: translateY(-40px); } 100% { transform: translateY(0px); } }
@-moz-keyframes smoothScroll { 0% { transform: translateY(-40px); } 100% { transform: translateY(0px); } }
@-o-keyframes smoothScroll { 0% { transform: translateY(-40px); } 100% { transform: translateY(0px); } }
@-ms-keyframes smoothScroll { 0% { transform: translateY(-40px); } 100% { transform: translateY(0px); } }
@keyframes smoothScroll { 0% { transform: translateY(-40px); } 100% { transform: translateY(0px); } }

.nav-icon { 
	display: none;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 9;
    width: 85px;
    height: 21px;
    position: absolute;
    top: 37px;
    right: 27px;
    border-radius: 0;
    margin-right: 0;
}
.nav-icon:before {
    content: "Menu";
    position: absolute;
    left: 37px;
    top: 1px;
    font-size: 16px;
    letter-spacing: -0.67px;
    font-family: "AktivGroteskEx-Bold", sans-serif;
    font-weight: 700;
    color: #040129;
}
.nav-icon span{
	display: block; 
	position: absolute; 
	height: 3px; 
	background: #000000; 
	opacity: 1; 
	left: 6px; 
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg); 
	-o-transform: rotate(0deg); 
	transform: rotate(0deg);  
	-webkit-transition: .25s ease-in-out; 
	-moz-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: .25s ease-in-out; 
	border-radius: 20px; 
 }
.nav-icon span:nth-child(1) { 
	top: 0px; 
	width: 25px; 
	left: auto; 
}
.nav-icon span:nth-child(2)	{ 
	top: 8px; 
	width: 25px;  
	left: auto;  
}
.nav-icon span:nth-child(3)	{ 
	top: 16px; 
	width: 25px;  
	left: auto;  
}
.nav-icon.open span:nth-child(1){ 
	top: 10px; 
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg); 
	-o-transform: rotate(135deg); 
	transform: rotate(135deg);
}
.nav-icon.open span:nth-child(2){ 
	opacity: 0; 
}
.nav-icon.open span:nth-child(3){ 
	top: 10px;
	-webkit-transform: rotate(-135deg); 
	-moz-transform: rotate(-135deg); 
	-o-transform: rotate(-135deg);
	transform: rotate(-135deg);  
}
.header .header_main .main_menu nav ul.sub-menu{ 
    background-color: $white;
    padding: 15px 15px;
    position: absolute;
    width: 190px;
    right: 0;
    top: 100%;
    z-index: 99;
    display: none;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.header .header_main .main_menu nav ul.sub-menu li{ margin-right: 0; padding-bottom: 10px; }
.header .header_main .main_menu nav ul.sub-menu li:last-child { padding-bottom: 0px;     margin-bottom: 0; }
.header .header_main .main_menu nav ul.sub-menu li a{  text-transform: capitalize;  color: #909090; }
.header .header_main .main_menu nav ul.sub-menu li a:hover {     color: #FF8001; }
.header .header_main .main_menu nav ul li.current-page-ancestor ul.sub-menu li a:hover {     color: #FF8001; }
.click_menu {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 3px;
    cursor: pointer;
    top: 5px;
    border-style: solid;
    transform: rotate(45deg);
    border-width: 0px 2px 2px 0;
    padding: 3px;
    background-position: right;
    z-index: 999;
    border-color: #fff;
    display: none;
}
.current-menu-item > a{
	color: #FF8001 !important;
}
.header .header_main .navbar ul li.current-menu-item > a:before{
    width: 100%;
    opacity: 1;
    visibility: visible;
}
.header .header_main .navbar .nav-btm .nav-top{
	display: none;
}

.btn {
    display: inline-block;
    font-weight: 700;
    font-size: 18px;
    background: transparent;
    padding: 14px 14px;
    text-align: center;
    border-radius: 0;
	border: 2px solid #040129;
	letter-spacing: -0.76px;
	font-family: 'AktivGroteskEx-XBold', sans-serif;
    transition: 0.3s all ease-in-out;
	line-height: 26px;
}
.btn:hover {
	background-image: linear-gradient(90deg, rgba(160, 10, 132, 1) 0%, rgba(57, 157, 224, 1) 100%);
    color: #ffffff;
}
.header .header_main .navbar ul li.btn:hover a{
	color: #111113;
}

main{
	flex: 1;
}

.header .header_main .navbar .nav-btm ul li.free-btn{
	opacity: 1;
    background-image: linear-gradient(90deg, rgb(160, 10, 132) 0%, rgb(57, 157, 224) 100%);
    margin-right: 20px;
    padding: 13px 24px 11px;
    border: 0;
	font-family: "AktivGroteskEx-Bold", sans-serif;
}
.header .header_main .navbar .nav-btm ul li.free-btn:last-child{
	margin-right: 0;
}
.header .header_main .navbar .nav-btm ul li.free-btn:hover{
	box-shadow: 0 1px 13px rgba(0, 0, 0, 0.5) inset;
	transition: 0.3s all ease-in-out;
}
.header .header_main .navbar .nav-btm ul li.free-btn a{
	color: #ffffff;
	
	img, svg{
		margin-left: 12px;
	}
}
.header .header_main .navbar .nav-btm ul li.login-btn{
    padding: 10px 13px 9px 22px;
	font-family: "AktivGroteskEx-Bold", sans-serif;
}
.header .header_main .navbar .nav-btm ul li.login-btn:hover a{
    color: #ffffff;
}
.header .header_main .navbar .nav-btm ul li.login-btn a img{
	margin-left: 12px;
}
.header .header_main .navbar .nav-top ul li.menu-item-has-children > a:before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    right: 1px;
    cursor: pointer;
    top: 5px;
    border-style: solid;
    transform: rotate(45deg);
    border-width: 0px 1px 1px 0;
    padding: 3px;
    z-index: 999;
    border-color: #040129;
}
.header .header_main .navbar .nav-top ul li:last-child a{
	padding-right: 0px;
}
.header .header_main .navbar{
	display: block;
}
.header .header_main .navbar .nav-btm ul li a:before {
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    right: 0;
    margin: 0 auto;
    height: 2px;
    bottom: -8px;
    background: #040129;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.header .header_main .navbar .nav-btm ul li a:hover:before {
    width: 50px;
    opacity: 1;
    visibility: visible;
}
.header .header_main .navbar ul li.current-menu-item > a:before {
    width: 50px;
    opacity: 1;
    visibility: visible;
}
.header .header_main .navbar ul li.current-menu-item > a {
	color: #040129 !important;
}
.header .header_main .navbar .nav-btm .right_button ul li a:before{
	display: none;
}

.header .header_main .navbar .nav-btm .right_button ul li:last-child.free-btn{
	background-image: none;
	border: 2px solid #040129;
	padding: 10px 13px 9px 22px;
}
.header .header_main .navbar .nav-btm .right_button ul li:last-child.free-btn a, .header .header_main .navbar .nav-btm .right_button ul li:last-child.free-btn a svg {
    color: #040129;
}
header .header_main .navbar .nav-btm .right_button ul li:last-child.free-btn a svg g{
	fill: #040129;
}
.header .header_main .navbar .nav-btm .right_button ul li:last-child.free-btn:hover{
	border-image: linear-gradient(#A00A84, #399DE0) 2;
    border-width: 2px;
    border-style: solid;
	    box-shadow: none;
}
.header .header_main .navbar .nav-btm .right_button ul li:last-child.free-btn:hover a{
    background: linear-gradient(90deg, #F240D2, #399de0, #00FFB6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.header .header_main .navbar .nav-btm .right_button ul li:last-child.free-btn:hover a svg g{
	fill: #040129;
}    
/****footer****/
.footer{
	background: url('../images/footer-bg.png') #F3F3F3 no-repeat;
	background-position: bottom;
	padding: 67px 0px 24px;
	background-size: 100% 272px;
	
.footer-top{
	margin-bottom: 50px;
	h5{
		
		letter-spacing: -0.84px;
		font-family: 'AktivGroteskEx-XBold', sans-serif;
		font-weight: 700;
		margin-bottom: 21px;
	}
	
	ul{
		padding: 0;
		margin: 0;
		
		li{
			display: block;
			font-size: 16px;
			color: #040129;
			font-weight: 500;
			letter-spacing: 0.49px;
			
		a{
			color: #040129;
			
			&:hover{
				color: #2118BC
			}
			
		}
		}
	}
}
.footer_btm{
	.footer_btmleft{
		ul{
			padding: 0;
			margin: 0;
			
			li{
				font-size: 16px;
				color: #2118BC;
				font-weight: 500;
				letter-spacing: 0.49px;
				margin-right: 25px;
				display: block;
				
			a{
				color: #2118BC;
				
			&:hover{
				color: #040129;
			}
			}
			&:last-child{
				margin-right: 0px;
			}
			
			}
		}
	}
	
	.footer_btmright{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		ul{
			padding: 0;
			margin: 0;
			justify-content: flex-end;
			 align-items: center;
			
			li{
				font-size: 16px;
				color: #2118BC;
				font-weight: 500;
				letter-spacing: 0.49px;
				margin-right: 26px;
				display: block;
				
			a{
				color: #2118BC;
				
			&:hover{
				color: #040129;
			}
			}
			
			
			}
		}
	
	}

}
}
.footer_btmright h6{
    font-size: 16px;
    color: #2118BC;
    font-weight: 500;
    letter-spacing: 0.49px;
    margin-right: 26px;
	margin-bottom: 0;
}
.footer-maintop .foot-1{
	width: 25%;
}
.footer-maintop .foot-1 .innfoot-1{
	margin-top: 33px;
}
.footer .footer_btm .footer_btmright ul li a i{
	font-size: 40px;
}
.footer .footer_btm .footer_btmright ul li.login, .footer_btmright a.login{
	border-image: linear-gradient(#A00A84, #399DE0) 2;
	border-width: 2px;
	border-style: solid;
	padding: 12px 21px 8px;
}
.footer .footer_btm .footer_btmright ul li.login:hover a, .footer_btmright a.login:hover{
	color: #ffffff;
}

/******Default WP Alignment Classes******/

.aligncenter,.alignleft,.alignright {
    display: block;
    padding: 0;
}

.aligncenter {
    float: none;
    margin: .5em auto 1em;
}

.alignright {
    float: right;
    margin: .5em 0 1em 1em;
}

.alignleft {
    float: left;
    margin: .5em 1em 1em 0;
}

.wp-caption {
    padding: 5px 0;
    border: 1px solid #555;
    background: #444;
    text-align: center;
}

.wp-caption img {
    display: inline;
}

.wp-caption p.wp-caption-text {
    margin: 5px 0 0;
    padding: 0;
    text-align: center;
    font-size: 75%;
    font-weight: 100;
    font-style: italic;
    color: #ddd;
}
.def_page{
	padding: 30px 0px;
}
.def_page h1{
	margin-bottom: 10px;
}
.def_page p{
	font-size: 17px;
	line-height: 30px;
	font-weight: 500;
	color: #040129;
	margin-bottom: 10px;
}
.def_page ul, .def_page ol {
	padding-left: 16px;
}
.def_page ul li{
	font-size: 19px;
	line-height: 28px;
	color: #444444;
}
.def_page ol li{
	font-size: 19px;
	line-height: 28px;
	color: #444444;
}
.search-form input {
    border: 1px solid rgba(69, 69, 69, 0.4);
    width: 278px;
    color: rgba(69, 69, 69, 0.5);
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    margin-right: 20px;
    padding: 3px 15px 4px;
    border-radius: 0px;
    background-color: #ffffff;
	    margin-bottom: 10px;
}
.search-form input[type=submit] {
    color: #ffffff;
    font-size: 18px;
    letter-spacing: -0.76px;
    opacity: 1;
    background-image: linear-gradient(90deg, rgb(160, 10, 132) 0%, rgb(57, 157, 224) 100%);
    margin-right: 20px;
    padding: 20px 35px 18px;
    border: 0;
    font-family: "AktivGroteskEx-Bold", sans-serif;
    -webkit-appearance: none;
    display: block;
    width: 100%;
    border-radius: 0;
    margin-top: 24px;
}
.search-form input[type=submit]:hover {
    background: linear-gradient(90deg, rgb(160, 10, 132) 0%, rgb(57, 157, 224) 100%);
    box-shadow: 0 1px 13px rgba(0, 0, 0, 0.5) inset;
    transition: 0.3s all ease-in-out;
}
.main{
	margin-top: 140px;
}
.imgTxt-sec-wrap{
	background-color: #F3F3F3;
}
.img-txt-row {
	margin-bottom: 204px;
	padding: 57px 0px 75px;
	position: relative;
	z-index: 2;
}
.customer-sec .container{
	max-width: 1344px;
}
.img-txt-row:before{
	content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 41.7%;
    height: 100%;
    background-color: #ffffff;
}
.imgTxt-sec-wrap .img-txt-row:nth-child(even){
	padding: 77px 0px 83px;
}
.imgTxt-sec-wrap .img-txt-row:nth-child(even):before {
	left: auto;
	right: 0;
	width: 47.7%;
}
.img-txt-row .container {
    position: relative;
}
.text-section {
    width: 54%;
    max-width: 54%;
    padding-right: 15px;
}
.imgTxt-sec-wrap .img-txt-row:nth-child(even) .text-section {
    margin-left: 46.1%;
}
.img-txt-row .img-sec {
    text-align: center;
    display: inline-block;
    position: absolute;
    width: 50%;
    max-width: 536px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    padding-left: 15px;
	
img{
	width: 100%;
}
}
.customer-sec .img-sec:before {
    content: "";
    position: absolute;
    right: -95px;
    bottom: -95px;
    margin: 0 auto;
    background: url(../images/oval-bg.png) no-repeat;
    width: 514px;
    height: 414px;
    background-size: cover;
    z-index: -1;
}

.imgTxt-sec-wrap .img-txt-row:nth-child(even) .img-sec {
    text-align: left;
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
}
.text-section h3{
	font-weight: 700;
	font-size: 35px;
	line-height: 48px;
	font-family: 'AktivGroteskEx-XBold', sans-serif;
	letter-spacing: -1.47px;
	margin-bottom: 10px;
	position: relative;
    z-index: 9;
}
.text-section p{
	font-weight: 500;
	font-size: 17px;
	line-height: 30px;
	letter-spacing: 0.22px;
	margin-bottom: 24px;
	position: relative;
    z-index: 9;
}
.text-section ul{
	padding-left: 0;
}
.text-section ul li{
	margin-right: 30px;
	font-weight: 500;
	letter-spacing: 0.22px;
	font-size: 17px;
	display: block;
	
&:last-child{
	margin-right: 0;
}
}
.text-section ul li a{
	color: #7B00A9;
}

.text-section ul li.btn{
	color: #ffffff;
	font-size: 18px;
	letter-spacing: -0.76px;
	opacity: 1;
    background-image: linear-gradient(90deg, rgb(160, 10, 132) 0%, rgb(57, 157, 224) 100%);
    margin-right: 20px;
    padding: 13px 35px 11px;
    border: 0;
    font-family: "AktivGroteskEx-Bold", sans-serif;
}
.text-section ul li.btn:hover{
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 13px inset;
    transition: all 0.3s ease-in-out 0s;
}
.text-section ul li.btn a{
	color: #ffffff;
}
.text-section ul li.btn.btn-border{
	border: 2px solid #040129;
	background-image: none;
	background-color: #ffffff;
}
.text-section ul li.btn.btn-border a{
	color: #040129;
}
.banner_sec{
	padding-top: 77px;
	
.banner_title{
	h1{
		margin-bottom: 15px;
	span{
		background-color: #040129;
		color: #ffffff;
		padding: 10px 5px 5px;
	}
	}
}
.banner_txtimg{
	margin-bottom: 25px;
	
	p{
		font-size: 21px;
		font-weight: 500;
		letter-spacing: 0.27px;
		padding-right: 10px;
	}
}
.banner_button{
	ul{
		padding: 0;
		margin-bottom: 27px;
		
		li{
			display: block;
			font-size: 18px;
			letter-spacing: -0.76px;
			font-weight: 500;
		}
	}
	
	em{
		display: block;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0.18px;
		
	a{	
		color: #2118BC;
	}
	}
}
}
.banner_sec .banner_button ul li:nth-child(2){
	padding: 0px 18px;
}
.banner_sec .container{
	position: relative;
}
.banner_inn{
    width: 51%;
    max-width: 51%;
    padding: 85px 0px;
}
.banner_inn .banner_rightimg {
    text-align: center;
    display: inline-block;
    position: absolute;
    width: 50%;
    max-width: 536px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    padding-left: 15px;
	
	img{
		max-width: 100%;
	}
}
.banner_sec .banner_button ul ul{
	margin-bottom: 0;
}
.banner_sec .banner_button ul li.free-btn{
    opacity: 1;
    background-image: linear-gradient(90deg, rgb(160, 10, 132) 0%, rgb(57, 157, 224) 100%);
    margin-right: 8px;
    padding: 13px 35px 11px;
    border: 0;
    font-family: "AktivGroteskEx-Bold", sans-serif;
}
.banner_sec .banner_button ul li.free-btn:hover{
	box-shadow: 0 1px 13px rgba(0, 0, 0, 0.5) inset;
	transition: 0.3s all ease-in-out;
}
.banner_sec .banner_button ul li.free-btn a{
    color: #ffffff;
}
.banner_sec .banner_button ul li.free-btn a img, .banner_sec .banner_button ul li.free-btn a svg{
    margin-left: 12px;
}
.banner_sec .banner_button ul li select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    flex: 1;
    cursor: pointer;
    border: 1px solid #CDCDCD;
    padding: 11px 101px 10px 25px;
    color: #040129;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.76px;
    outline: 0;
    background: url(../images/arrow.svg) #fff no-repeat;
    background-position: right 19px top 21px;
}
.sharing_list{
	padding: 139px 0px 161px;
}
.sharing_list .row{
    margin: 0px -35px;
}
.sharing_list .sharing_col{
	padding: 0px 35px;
}
.sharing_box{
	.sharing_boximg{
		max-width: 107px;
	}
	.sharing_boxtxt{
		width: calc(100% - 107px);
		padding-left: 27px;
		
	h4{
		font-family: "AktivGroteskEx-XBold", sans-serif;
		letter-spacing: -0.92px;
		margin-bottom: 9px;
	}
	p{
		font-weight: 500;
		font-size: 17px;
		letter-spacing: -0.22px;
	}
	}
}
.blue_sec{
	background-color: #030129;
	padding-bottom: 150px;
}
.study_main{
	padding-top: 110px;
}
.study_slider{
	position: relative;
}
.study_slider .slick-list{
	padding-top: 29px;
}
.study_boxslide{
	padding: 77px 0px;
    width: 62%;
    max-width: 62%;
	margin-left: 38%;
}
.study_slidetitle{
	margin-bottom: 23px;
	h2{
		font-size: 50px;
		line-height: 54px;
		font-weight: 700;
		letter-spacing: -2.1px;
		color: #ffffff;
		font-family: "AktivGroteskEx-Bold", sans-serif;
	}
}
.study_smalltxt {
	margin-bottom: 41px;
	
	h6{
		font-size: 18px;
		color: #ffffff;
		font-family: "AktivGroteskEx-Bold", sans-serif;
		letter-spacing: -0.76px;
		font-weight: 700;
	}
}
.study_smallimg{
    display: inline-block;
    position: absolute;
    width: 50%;
    max-width: 440px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
	
span{
    position: absolute;
    right: 13px;
    top: -29px;
	
img{
	width: 78px;
}
}
}
.study_boxslide blockquote{
	font-size: 17px;
	letter-spacing: 0.22px;
	font-weight: 500;
	padding-left: 23px;
	position: relative;
	color: #ffffff;
	max-width: 585px;
	
&:before{
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 3px;
	height: 100%;
	background-color: #2118BC;
}
}
.conect_app{
	width: 88.2%;
    margin-left: auto;
	padding: 110px 0px;
	overflow: hidden;
	
.conect_title{
	max-width: 343px;
	margin-bottom: 45px;
	
	h2{
		font-size: 40px;
		letter-spacing: -1.68px;
		margin-bottom: 20px;
		font-family: "AktivGroteskEx-Bold", sans-serif;
		color: #ffffff;
	}
	p{
		font-size: 19px;
		font-weight: 500;
		color: #ffffff;
		letter-spacing: 0.24px;
	}
}
}
.conect_slider, #conect_slider{
	margin-left: 57px;
	width: calc(100% - 401px);
}
.conect_img{
	min-height: 55px;
	margin-bottom: 24px;

}
.conect_txt p {
	font-size: 19px;
	line-height: 24px;
	font-weight: 500;
	color: #ffffff;
	letter-spacing: 0.24px;
}
.conect_button{
	width: 100%;
}
.conect_button a.btn-border{	
	border: 1px solid #ffffff;
	padding: 11px 24px 9px 17px;
	color: #ffffff;
	letter-spacing: -0.76px;
	font-size: 18px;
	font-family: "AktivGroteskEx-Bold", sans-serif;
	display: inline-block;
}	
.conect_button a img, .conect_button a svg{
	margin-right: 14px;
}

.conect_slider .slick-prev, #conect_slider .splide__arrow--prev {
	display: none !important;
}
.conect_slider .slick-next, #conect_slider .splide__arrow--next {
    right: 73px;
    z-index: 9;
    top: auto;
    bottom: -76px;
    width: 78px;
    height: 50px;
    background-color: transparent;
    border: 1px solid #fff;
	opacity: 1;
    border-radius: 0;
}
.conect_slider .slick-next:before, #conect_slider .splide__arrow--next:before {
    content: "";
    position: absolute;
    width: 33px;
    height: 20px;
    background: url(../images/right-arrow.png);
    top: 50%;
    z-index: 999;
    background-size: cover;
    right: 0;
    opacity: 1;
    left: 0;
    margin: 0 auto;
    transform: translateY(-50%);
}
.conect_slider .slick-list {
    padding-left: 0!important;
    padding-right: 160px!important;
}
.conect_slider .slick-slide{
    margin-right: 60px;
}
.worldwide_sec{
	margin-bottom: 93px;
}
.worldwide_title{
	margin-bottom: 44px;
	text-align: center;
	
	h3{
		font-size: 29px;
		letter-spacing: -1.22px;
		font-weight: 700;
		font-family: "AktivGroteskEx-Bold", sans-serif;
	}
}
.worldwide_logo{
	width: 100px;
	height: 100px;
	display: flex;
    align-items: center;
    justify-content: center;
}
.cust_breadcrumb_sec{
	padding: 50px 0px 0px;
}

.industry_sec{
	padding: 30px 0px 0px;
	
.industry_head {
	margin-bottom: 36px;
	
	em{
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0.18px;
	}
}
}
.industry_row .img-sec{
    text-align: left;
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
}
.industry_row .container{
	padding: 0;
}
.industry_row .text-section {
    margin-left: 42.9%;
}
.industry_sec .img-txt-row:before{
	display: none;
}
.industry_sec .img-txt-row .img-sec {
    max-width: 685px;
	padding: 0;
}
.video_box {
    width: 100%;
	max-width: 965px;
	margin: 0 auto;
    position: relative;
    margin-bottom: 48px;
	
&:before{
	content: "";
    position: absolute;
    width: 1280px;
    height: 990px;
    background: url(../images/oval-bg.png);
    top: -130px;
    background-size: cover;
    left: -175px;
    z-index: -1;
}
}
.video_box img {
    width: 100%;
}
.video_box a.video_btn {
    position: absolute;
    width: 75px;
    height: 75px;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-57%);
}
a.video_btn{
    background: linear-gradient(to bottom right, #A00A84, #399DE0);
}
.industry_sec .banner_title h1 {
    letter-spacing: -2.1px;
    font-family: "AktivGroteskEx-XBold", sans-serif;
    margin-bottom: 15px;
}
.industry_sec .text-section {
    width: 59%;
    max-width: 59%;
}
.industry_sec .banner_title{
	position: relative;
    z-index: 9;
}
.industry_sec .box-wrapper{
	padding-left: 107px;
	max-width: 820px;
}
.industry_sec .text-section p{
	font-size: 21px;
    letter-spacing: 0.27px;
}
.industry_sec .text-section ul {
    margin-bottom: 22px;
}
.industry_sec .box-wrapper em{
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.18px;
}
.industry_sec .img-txt-row {
    margin-bottom: 167px;
    padding: 57px 0px;
}
.industry_wrap .img-txt-row {
    padding: 122px 0px;
	margin-bottom: 0;
}
.industry_wrap .text-section p{
	margin-bottom: 0;
}
.industry_wrap .text-section h3 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1.68px;
    margin-bottom: 20px;
}
.industry_wrap .img-txt-row .img-sec {
    max-width: 500px;
}
.industry_wrap .text-section {
    width: 55%;
    max-width: 55%;
}
.blue_sec.blue_app {
    padding: 60px 0px 140px;
}
.blue_sec.blue_app .study_main {
    padding-top: 50px;
}
.blue_sec.blue_app .study_slidetitle h2 {
    font-size: 36px;
    line-height: 54px;
    letter-spacing: -1.51px;
}
.industry_worldsec{
	padding: 149px 0px 170px;
}
.industry_worldsec .worldwide_slider {
	margin-bottom: 135px;
}
.video_title{
	max-width: 768px;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 35px;
h2{
	font-size: 36px;
    line-height: 54px;
    letter-spacing: -1.51px;
	font-family: "AktivGroteskEx-XBold", sans-serif;
}
}

.industry_imgTxt.imgTxt-sec-wrap .img-txt-row:nth-child(even) {
    margin-bottom: 183px;
}
.blue_sec.blue_app .study_slider .slick-list {
    padding-top: 58px;
}
.responsive__tabs ul.scrollable-tabs {
  background-color: #333;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  text-transform: uppercase;
  flex-direction: row;
}
.responsive__tabs ul.scrollable-tabs li {
  list-style-type: none;
}
.responsive__tabs ul.scrollable-tabs li a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}
.responsive__tabs ul.scrollable-tabs li a:hover, .responsive__tabs ul.scrollable-tabs li a.active {
  background-color: #777;
}
.tab-content > .tab-pane {
    color: #fff;
}

#body .out-wrap {
  position: relative;
  padding: 30px;
  box-shadow: 0 6px 10px -2px rgba(0, 0, 0, 0.25);
  width: 400px;
  margin: 50px auto;
  background: #ffc107;
}
#body .out-wrap #tabs {
  border-bottom: 0;
  margin: 0 -50px 20px;
  background: #303030;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  flex-wrap: nowrap !important;
}
#body .out-wrap #tabs::-webkit-scrollbar {
  display: none;
}
#body .out-wrap #tabs li {
  width: auto;
  white-space: nowrap;
  flex: 1;
}
#body .out-wrap #tabs li a {
  border: 0;
  border-radius: 0;
  cursor: pointer;
  margin: 0;
  color: #fff;
  text-transform: uppercase;
}
#body .out-wrap #tabs li a:hover {
  background: #ddd;
  color: #303030;
}
#body .out-wrap #tabs li.active a {
  background: #fff;
  color: #3D5A68;
}
#body .out-wrap .tab-content {
  overflow: hidden !important;
}
.industry_sec .text-section ul li.btn.btn-border {
    background-color: transparent;
}
.reseller_sec{
	padding: 75px 0 0;
}
.reseller_head{
	margin-bottom: 70px;
	
	h1{
		font-family: "AktivGroteskEx-Bold", sans-serif;
	}
}
.tdm_main{
	position: relative;
}
.tdm_img{
	max-width: 1002px;
	
	img{
		width: 100%;
	}
}
.tdm_txt{
	background-color: #040129;
	padding: 54px 54px;
	max-width: 643px;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
p{
	color: #ffffff;
	font-size: 19px;
	margin-bottom: 32px;
}
}

.tdm_txt a.btn {
    color: #ffffff;
    font-size: 18px;
    letter-spacing: -0.76px;
    opacity: 1;
    background-image: linear-gradient(90deg, rgb(160, 10, 132) 0%, rgb(57, 157, 224) 100%);
    margin-right: 20px;
    padding: 13px 35px 11px;
    border: 0;
    font-family: "AktivGroteskEx-Bold", sans-serif;
}
.sharing_list.reseler_list {
    padding: 112px 0px 203px;
}
.getstarted_sec{
	background-color: #040129;
	padding: 87px 0 120px;
}
.getstarted_head{
	max-width: 1015px;
	margin: 0 auto;
	padding-bottom: 40px;
	margin-bottom: 90px;
	position: relative;
	
	&:before{
		content:'';
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		margin: 0 auto;
		background: url('../images/line-bg.png') no-repeat;
		width: 158px;
		height: 5px;
	}
	h1{
		color: #ffffff;
		font-family: "AktivGroteskEx-Bold", sans-serif;
		position: relative;
	}
}
.getstarted_sec .img-txt-row:before{
	display: none;
}
.getstarted_sec .imgTxt-sec-wrap{
	background-color: #040129;
	position: relative;
}
.getstarted_sec .imgTxt-sec-wrap:before{
	content: "";
    position: absolute;
    left: 80px;
    bottom: 75px;
    margin: 0 auto;
    background: url(../images/shape-1.svg) no-repeat;
    width: 449px;
    height: 449px;
    opacity: 0.15;
    background-size: cover;
}
.getstarted_sec .imgTxt-sec-wrap .text-section h3 {
    font-size: 30px;
	color: #ffffff;
}
.getstarted_sec .imgTxt-sec-wrap .text-section p {
	color: #ffffff;
}
.getstarted_sec .imgTxt-sec-wrap .container {
    max-width: 1320px;
}
.getstarted_sec .imgTxt-sec-wrap .img-txt-row {
    margin-bottom: 137px;
}
.getstarted_sec .imgTxt-sec-wrap .img-txt-row:nth-child(even) {
    margin-bottom: 90px;
}
.getstarted_btm p{
	color: #ffffff;
	font-size: 18px;
	letter-spacing: -0.76px;
	font-family: "AktivGroteskEx-Bold", sans-serif;
	
	a{
		color: #01EBAB;
		display:inline-block;
	i{
		margin-left: 10px;
	}
	}
}

.reseller_signange {
	padding: 114px 0px 185px;
}

.reseller_formhead{
	h1{
		padding-bottom: 30px;
		margin-bottom: 40px;
		position: relative;
		
	&:before{
		content:'';
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		margin: 0 auto;
		background: url('../images/line-bg.png') no-repeat;
		width: 158px;
		height: 5px;
	}
	}
	p{
		font-size: 21px;
		max-width: 715px;
		margin: 0 auto;
	}
}
.reseller_form{
	max-width: 715px;
	margin: 0 auto;
	padding: 60px 65px;
	background-color: #040129;

label{
	color: #ffffff;
	font-size: 18px;
	position: relative;
	margin-bottom: 12px;
}
}
.reseller_form .form-group a{
	color: #ffffff;
	text-decoration: underline;
}
.signup_form .form-group.input_check {
	margin-top: 25px;
}
.signup_form .form-group.input_check input[type=checkbox] {
	padding: 0;
	height: initial;
	width: initial;
	margin-bottom: 0;
	display: none;
	cursor: pointer;
}

.signup_form .form-group.input_check label:before {
	content:'';
	-webkit-appearance: none;
	background-color: #ffffff;
	width: 30px;
	height: 30px;	
	display: inline-block;
	position: relative;
	vertical-align: middle;
	cursor: pointer;
	margin-right: 12px;
}

.signup_form .form-group.input_check input[type=checkbox]:checked + label:after {
	content: "";
    display: block;
    position: absolute;
    top: 6px;
    left: 13px;
    width: 6px;
    height: 14px;
    border: solid #040129;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.reseller_form .submit_btn{
	margin-top: 30px;
}
.reseller_form .form-group:nth-child(7) label{
	margin-bottom: 0;
    top: 4px;
}
.reseller_formhead {
	margin-bottom: 65px;
}
.reseller_form input, .reseller_form select{
	background-color: #ffffff;
    padding: 12px 15px;
    font-size: 16px;
    color: #040129;
    border-radius: 0;
}
.reseller_form .form-group{
	margin-bottom: 15px;
}
a.btn-gradient{
    color: #ffffff;
    font-size: 18px;
    letter-spacing: -0.76px;
    opacity: 1;
    background-image: linear-gradient(90deg, rgb(160, 10, 132) 0%, rgb(57, 157, 224) 100%);
    padding: 13px 35px 11px;
    border: 0;
    font-family: "AktivGroteskEx-Bold", sans-serif;
	display: inline-block;
}
a.btn-gradient:hover{
	box-shadow: 0 1px 13px rgba(0, 0, 0, 0.5) inset;
	transition: 0.3s all ease-in-out;
}
a.btn-gradient img, a.btn-gradient svg{
	margin-left: 12px;
}
a.btn-gradient svg g{
	fill: #ffffff;
}
.reseller_form input[type=submit]{
    color: #ffffff;
    font-size: 18px;
    letter-spacing: -0.76px;
    opacity: 1;
    background-image: linear-gradient(90deg, rgb(160, 10, 132) 0%, rgb(57, 157, 224) 100%);
    margin-right: 20px;
    padding: 20px 35px 18px;
    border: 0;
    font-family: "AktivGroteskEx-Bold", sans-serif;
    -webkit-appearance: none;
    display: block;
    width: 100%;
}
.reseller_form input[type=submit]:hover{
	box-shadow: 0 1px 13px rgba(0, 0, 0, 0.5) inset;
	transition: 0.3s all ease-in-out;
}
.advice_sec{
	margin-bottom: 150px;
}
.advice_img{
	width: 50%;
	
img{
	width: 100%;
	height: 100%;
    object-fit: cover;
}
}
.advice_txt{
	background-color: #040129;
	padding: 70px 55px;	
	width: 50%;
	background-image: linear-gradient(45deg, #090353, #040129);
h3{
	font-size: 35px;
	color: #ffffff;
	margin-bottom: 30px;
	font-family: "AktivGroteskEx-Bold", sans-serif;
}
p{
	margin-bottom: 25px;
	color: #ffffff;
}
a.btn-gradient{
	margin-bottom: 25px;
}
h6{
	color: #ffffff;
	font-size: 18px;
	letter-spacing: -0.76px;
	font-family: "AktivGroteskEx-Bold", sans-serif;
	a{
		color: #00FFB6;
	}
}
}

.tt-select { position: relative; height: auto; padding: 0px; text-align: left; }
.tt-select select { display: none; }
.select-selected { background-color: #fff; color: #a8afb7; }
.select-selected:after { position: absolute; content: ""; top: 60px; right: 14px; width: 13px; height: 10px; background: url('../images/select-arrow.png') no-repeat; }
.select-selected {     background-color: #ffffff;
    font-size: 17px;
    color: #040129;
    font-weight: 500;
    padding: 12px 32px 12px 18px;
    width: 100%;
    cursor: pointer;
    letter-spacing: 0.22px;}
.select-items div { font-size: 14px; color: #040129; font-weight: 300; padding: 0px 19px 6px; }
.select-items div:last-child { padding-bottom: 0; }
.select-items { position: absolute;  background-color: #ffffff;  top: 100%; left: 0;  right: 0; z-index: 9;  padding: 9px 0px; border-radius: 0px;
filter: drop-shadow(0 0 3px rgba(67,112,221,0.29)); }
.select-hide { display: none; }
.select-items div:hover, .same-as-selected { background-color: #f3f6fb; }

.reseller_form .form-group.input_check { margin-top: 25px; }

.getstarted_sec .img-sec:before{
	content: "";
    position: absolute;
    right: -95px;
    bottom: -95px;
    margin: 0 auto;
    background: url(../images/oval-bg.png) no-repeat;
    width: 514px;
    height: 414px;
    background-size: cover;
    z-index: -1;
}
.getstarted_sec .imgTxt-sec-wrap .img-txt-row:nth-child(even) .img-sec:before{
	right: -160px;
    width: 692px;
    height: 558px;
    bottom: -110px;
    background-size: cover;
}

.study_slider .slick-dots {
    bottom: -7px;
    width: 100px;
    left: -265px;
    right: 0;
    margin: 0 auto;
}

.slick-dots li button {
    width: 13px;
    height: 13px;
}
.slick-dots li button:before {
    width: 13px;
    height: 13px;
    opacity: 1;
    font-size: 0;
    border: 2px solid #2118BC;
}
.slick-dots li.slick-active button:before {
	background: url('../images/slick-bg.png') no-repeat;
	background-size: cover;
	opacity: 1;
	border: 0;
}
.responsive_tabs{
	background-color: #030129;	
	padding: 150px 0px 30px;
}
.responsive_tabs ul.tablist{
	margin-bottom: 145px;
	padding: 0;
	display: flex;
    justify-content: center;
	align-items: center;
	position: relative;
	min-height: 68px;
	
&:before{
	content: "";
	position: absolute;
	left: 0px;
	bottom: 0px;
	background: #2118BC;
	width: 100%;
	height: 2px;
}
}
.responsive_tabs ul li {
	padding-bottom: 27px;
}
.responsive_tabs ul li a{
	color: #ffffff;
	font-size: 20px;
	letter-spacing: -0.84px;
	font-weight: 700;
	font-family: "AktivGroteskEx-Bold", sans-serif;
	padding: 0px 25px;
}
.responsive_tabs .stNavMainLeft, .responsive_tabs .stNavMainRight{
	display: none;
}
.tab_content {
	color: #ffffff;
	font-size: 20px;
}

.platform_title {
	margin-bottom:70px;
	h1{
		color: #ffffff;
		font-family: "AktivGroteskEx-Bold", sans-serif;
	}
}
.tab_title {
	margin-bottom: 14px;
	
	h2{
		font-size: 50px;
		color: #ffffff;
		font-family: "AktivGroteskEx-Bold", sans-serif;
		letter-spacing: -2.1px;
	}
}
.tab_content ul.tabs_ul{
	padding-left: 34px;
	margin-bottom: 36px;
	li{
		display: block;
		font-size: 19px;
		line-height: 44px;
		letter-spacing: 0.24px;
		color: #ffffff;
		position: relative;
		padding: 0;
		
	&:before{
		content: "";
		position: absolute;
		left: -34px;
		top: 14px;
		background: url(../images/check.svg) no-repeat;
		width: 22px;
		height: 17px;
		background-size: cover;
	}
	}
}
.tab_content a.btn-gradient img{
	margin-left: 12px;
}
.tab_main{
	position: relative;
	margin-left: 100px;
}
.tab_content {
    width: 50%;
    max-width: 50%;
    padding-right: 15px;
    margin-left: 50.1%;
}
.tab_content .tab_slider, .tab_content .tab_img {
    display: inline-block;
    position: absolute;
    width: 50%;
    max-width: 544px;
    top: 50%;
    transform: translateY(-50%);
    text-align: left;
    left: 0;
}
.tab_content .tab_slider img, .tab_content .tab_img img{
	width: 100%;
}
.featured_client .container{
	max-width: 1267px;
}
.responsive_tabs ul li.ui-tabs-active.ui-state-active {
	position: relative;
    top: -7px;
}
.responsive_tabs ul li.ui-tabs-active.ui-state-active:before{
	content: "";
	position: absolute;
	left: 0px;
	bottom: -21px;
	width: 100%;
	height: 5px;
	border-bottom: 5px solid transparent;
    border-image: linear-gradient(0.25turn, #F240D2 , rgba(82, 152, 199, 0.48), #00FFB6);
    border-image-slice: 1;
}
.responsive_tabs ul li.ui-tabs-active.ui-state-active a{
    font-size: 27px;
	background: linear-gradient(90deg, #F240D2 , rgba(82, 152, 199, 0.48), #00FFB6);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.tab_slider .slick-dots {
    bottom: -55px;
    width: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.featured_client{
	margin-bottom: 235px;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		width: 41.7%;
		height: 455px;
		background-color: #ffffff;
		z-index: -1;
	}
}
.featured_box{
	display: flex;
	flex-direction: row-reverse;
	min-height: 590px;
	flex-wrap: wrap;
}
.featured_txt{
	background-color: #ffffff;
	padding: 51px 10px 51px 72px;
	width: calc(100% - 640px);
	max-height: 455px;
	h3{
		font-size: 29px;
		color: #000000;
		letter-spacing: -1.22px;
		font-family: "AktivGroteskEx-XBold", sans-serif;
		margin-bottom: 10px;
	}

}
.featured_img{
	position: relative;
	width: 640px;
	
img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}
}
.featured_img1{
	position: absolute;
	left: 0;
	top: 61px;
	width: 343px;
	height: 240px;
}
.featured_img2{
	position: absolute;
	right: 23px;
	top: 0;
	width: 263px;
	height: 301px;
}
.featured_img3{
	position: absolute;
    left: 146px;
    top: 313px;
    width: 110px;
    height: 110px;
}
.featured_img4{
	position: absolute;
    right: 171px;
    top: 313px;
    width: 197px;
    height: 225px;
}
.featured_img5{
	position: absolute;
    right: -252px;
    top: 313px;
    width: 413px;
    height: 275px;
}
.featured_slider .slick-dots {
    bottom: 330px;
    width: 100px;
    left: 238px;
    right: 0;
    margin: 0 auto;
}
.countles_sec{	
	padding: 186px 0px 129px;

	.countles_title{
		
	h2{
		font-size: 50px;
		letter-spacing: -2.1px;
		font-family: "AktivGroteskEx-Bold", sans-serif;
		margin-bottom: 22px;
	}
	h6{
		letter-spacing: -0.76px;
		font-family: "AktivGroteskEx-Bold", sans-serif;
	}
	}
}
.countles_main{
	overflow: hidden;
	padding: 50px 0 60px;
}
.countles_left{
	width: 32.5%;
	position: relative;
}
.countles_big{
	max-width: 675px;
	position: relative;
	
	&:before{
		content: "";
		position: absolute;
		left: 0px;
		top: 0px;
		background: url(../images/oval-bg.png) no-repeat;
		width: 875px;
		height: 875px;
		background-size: contain;
		z-index: -1;
		opacity: 0.37;
	}
}
.countles_right{
	width: 32.5%;
	position: relative;
}
.countles_box{
	background-color: #ffffff;
	box-shadow: 0 2px 44px 0px rgba(0, 0, 0, 0.26);
	
	.countles_img{
		img{
			width: 100%;
		}
	}
	.countles_txt{
		padding: 21px 23px;
		
		.countles_txtimg{
			max-width: 65px;
		}
		.countles_txthead{
			width: calc(100% - 65px);
			padding-left: 25px;
			
			h6{
				letter-spacing: -0.76px;
				font-family: "AktivGroteskEx-Bold", sans-serif;
				margin: 0;
			}
			p{
				line-height: 22px;
				letter-spacing: 0.22px;
			}
		}
	}
}
.countles_bg{
	width: 203px;
	height: 203px;
	background-color: #ffffff;
	padding: 25px 33px;
	text-align: center;
	position: absolute;
	display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
	
&:before{
	content: "";
    position: absolute;
    left: -116px;
    top: -65px;
    background: url(../images/oval-bg.png) no-repeat;
    width: 400px;
    height: 400px;
    background-size: contain;
    z-index: -1;
    opacity: 0.37;
}
}

.countles_sm{
	width: 155px;
	height: 155px;
	background-color: #ffffff;
	padding: 20px 25px;
	text-align: center;
	position: absolute;
	display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
	
&:before{
	content: "";
    position: absolute;
    left: -93px;
    top: -63px;
    background: url(../images/oval-bg.png) no-repeat;
    width: 350px;
    height: 350px;
    background-size: contain;
    z-index: -1;
    opacity: 0.37;
}
}
.countles_bg img{
	width: 54px;
	margin-bottom: 15px;
}
.countles_sm img{
	width: 54px;
	margin-bottom: 15px;
}
.countles_left h6, .countles_right h6{
	letter-spacing: -0.76px;
	font-family: "AktivGroteskEx-Bold", sans-serif;
	margin: 0;
}
.countles_bg>a{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9;
}
.countles_sm>a{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9;
}
.sm_box1{
    left: 0px;
    top: -10px;
}
.sm_box2{
    right: 54px;
    top: 7px;
}
.sm_box3{
    left: 70px;
    top: 220px;
	
img{
	width: 64px;
}
}
.sm_box4{
    right: 31px;
    top: 321px;
	
img{
	width: 58px;
}
}
.sm_box5{
    left: 230px;
    bottom: 0px;
	
img{
	width: 69px;
}
}
.sm_box6{
	width: 181px;
	height: 181px;
	top: -8px;
	right: 70px;
}
.sm_box7{
    top: 220px;
    right: 0px;
}
.sm_box8{
    top: 180px;
    left: 120px;
}
.sm_box9{
    bottom: 0px;
    left: 45px;
}
.sm_box10{
    bottom: -35px;
    right: 54px;
}


.ui-scroll-tabs-view {
	-ms-touch-action: inherit;
	touch-action: inherit;
	z-index: 1;
	overflow: scroll;
	overflow-y: hidden; 
}
.ui-scroll-tabs-view::-webkit-scrollbar {
    display: none; 
}
.ui-scroll-tabs-header {
	position: relative;
	overflow: hidden;
}
.ui-scroll-tabs-header ul.ui-tabs-nav {
    position: relative;
    white-space: nowrap; 
}
.ui-scroll-tabs-header ul.ui-tabs-nav li {
    display: inline-block;
    float: none;
    padding: 0;
}

.advertiser_sec{
	background-color: #F3F3F3;
	padding: 153px 0 119px;
}
.advertiser_sec .getstarted_head{
	margin-bottom: 135px;
}
.administrator_sec .getstarted_head{
	margin-bottom: 25px;
}
.advertiser_sec .getstarted_head h1, .allfeatures_sec .getstarted_head h1 , .administrator_sec .getstarted_head h1 {
    color: #040129;
}
.getstarted_sec.advertiser_sec .imgTxt-sec-wrap .text-section h3, .getstarted_sec.administrator_sec .imgTxt-sec-wrap .text-section h3 {
    color: #040129;
}
.getstarted_sec.advertiser_sec .imgTxt-sec-wrap .text-section p, .getstarted_sec.administrator_sec .imgTxt-sec-wrap .text-section p {
    color: #040129;
}
.getstarted_sec.advertiser_sec .imgTxt-sec-wrap .img-txt-row , .getstarted_sec.administrator_sec .imgTxt-sec-wrap .img-txt-row{
    margin-bottom: 30px;
}
.advertiser_sec .getstarted_btm p{
	color: #040129;
}
.advertiser_sec .getstarted_btm p a{
	color: #7B00A9;
}
.getstarted_sec.advertiser_sec .imgTxt-sec-wrap:before, .getstarted_sec.administrator_sec .imgTxt-sec-wrap:before {
    content: "";
    position: absolute;
    left: auto;
    right: 195px;
    bottom: auto;
    top: -225px;
    margin: 0 auto;
    background: url(../images/shape2.svg) no-repeat;
    width: 346px;
    height: 352px;
    opacity: 0.15;
    background-size: contain;
}
.getstarted_sec.administrator_sec .imgTxt-sec-wrap:before {
	top: -152px;
}
.getstarted_sec.advertiser_sec .imgTxt-sec-wrap, .getstarted_sec.administrator_sec .imgTxt-sec-wrap {
    background-color: #F3F3F3;
}
.advertiser_title h1{
	position: relative;
}
.advertiser_title h1:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    background: url(../images/line-bg.png) no-repeat;
    width: 158px;
    height: 5px;
}
.allfeatures_sec{
	margin-bottom: 175px;
}
.allfeatures_sec .getstarted_head {
	margin-bottom: 75px;
}
.card {
	background-color: #FFFFFF;
	padding: 30px 30px;
}
.card_top{
	margin-bottom: 8px;
	
img{
	width: 64px;
}
h4{
	width: calc(100% - 64px);
	margin-left: 30px;
	letter-spacing: -0.92px;
	font-family: "AktivGroteskEx-Bold", sans-serif;
}
}
.card-columns{
	display: flex;
    flex-wrap: wrap;
    columns: 3;
}
.card_box{
	background-color: #fff;
	margin-bottom: 25px;
	width: 32.18%;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
	padding: 30px 30px;
	min-height: 222px;
	
&:before{
	content: "";
    position: absolute;
    left: -65px;
    top: -130px;
    width: 600px;
    height: 600px;
    z-index: -1;
    opacity: 0.5;
    background: url(../images/oval-bg.png) 0% 0%/contain no-repeat;
}

}
.card_box:nth-child(3n+2){
	margin: 0px 25px 25px;
}
/*.card-columns .card_box:nth-child(1){
	padding-bottom: 57px;
}
.card_box.h-203{
	min-height: 203px;
}
.card_box.h-234{
	min-height: 234px;
}*/


.freedemo_sec {
	margin-bottom: 145px;
}
.freedemo_sec .advice_txt {
    background-image: linear-gradient(270deg, #090353, #040129);
	height: 100%;
}
.freedemo_sec .advice_txt h3 {
    margin-bottom: 32px;
	font-size: 30px;
}
.freedemo_sec .advice_txt p {
    margin-bottom: 32px;
}
.freedemo_sec .advice_txt a img, .freedemo_sec .advice_txt a svg{
	margin-left: 10px;
}
.getstarted_sec.user_sec .imgTxt-sec-wrap .img-txt-row {
    margin-bottom: 160px;
}

.user_sec .imgTxt-sec-wrap .img-txt-row:nth-child(odd) .text-section {
    margin-left: 46.1%;
}
.user_sec .imgTxt-sec-wrap .img-txt-row:nth-child(even) .text-section {
    margin-left: 0;
}
.user_sec .imgTxt-sec-wrap .img-txt-row:nth-child(odd) .img-sec {
    text-align: left;
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
}
.user_sec .imgTxt-sec-wrap .img-txt-row:nth-child(even) .img-sec {
    text-align: left;
	left: auto;
    right: 0;
    padding-left: 0;
    padding-right: 0;
}
.getstarted_sec.user_sec .imgTxt-sec-wrap .img-txt-row:nth-child(even) {
    margin-bottom: 90px;
}
.getstarted_sec.user_sec .imgTxt-sec-wrap:before{
	display: none;
}
.getstarted_sec.user_sec .img-txt-row:nth-child(odd) .img-sec:after {
    content: "";
    position: absolute;
    left: -125px;
    bottom: auto;
    top: -170px;
    margin: 0 auto;
    background: url(../images/shape2.svg) no-repeat;
    width: 346px;
    height: 352px;
    opacity: 0.15;
    background-size: contain;
    z-index: -1;
}
.getstarted_sec.user_sec .img-txt-row:nth-child(even) .img-sec:after {
    content: "";
    position: absolute;
    right: -90px;
    bottom: auto;
    top: -220px;
    margin: 0 auto;
    background: url(../images/shape2.svg) no-repeat;
    width: 346px;
    height: 352px;
    opacity: 0.15;
    background-size: contain;
    z-index: -1;
}
.administrator_sec {
    background-color: #F3F3F3;
    padding: 150px 0 179px;
}
.administrator_sec .getstarted_btm p{
	color: #040129;
}
.administrator_sec .getstarted_btm p a{
	color: #2118BC;
}

.everyuser_sec .imgTxt-sec-wrap .img-txt-row, .getstarted_sec .imgTxt-sec-wrap .img-txt-row:nth-child(even){
	margin-bottom: 0;
}	
.everyuser_sec .img-txt-row:nth-child(odd) .img-sec:after {
    content: "";
    position: absolute;
    right: -125px;
    bottom: auto;
    top: -170px;
    margin: 0 auto;
    background: url(../images/shape2.svg) no-repeat;
    width: 346px;
    height: 352px;
    opacity: 0.15;
    background-size: contain;
    z-index: -1;
}
.signage_sec .img-txt-row {
    margin-bottom: 0 !important;
}
.signage_sec .industry_row .text-section {
    margin-left: 0;
}
.signage_sec .box-wrapper {
    padding-left: 0px;
	padding-right: 54px;
    max-width: 820px;
}
.signage_sec .img-txt-row .img-sec {
    max-width: 703px;
	left: auto;
}
.signage_sec .banner_title h1 {
    font-size: 43px;
	line-height: 58px;
}
.signage_sec .text-section ul li a {
    color: #ffffff;
}
.casting_sec {
	padding-bottom: 115px;
	
	h4{
		letter-spacing: -0.92px;
		font-family: "AktivGroteskEx-Bold", sans-serif;
		margin-bottom: 50px;
	}
	ul{
		padding: 0;
		padding-top: 50px;
	
	li{
		display: inline-block;
		margin-right: 25px;
		background-color: #ffffff;
		padding: 20px 15px 25px;
		width: 23.77%;
		position: relative;
		border-bottom: 1px solid transparent;
		
	&:last-child{
		margin-right: 0;
	}
	}
	}
}
.casting_box{
	text-align: center;
}
.casting_img{
	width: 95px;
	height: 95px;
	margin: 0 auto;
	margin-bottom: 25px;
	margin-top: -65px;
	
	img{
		width: 100%;
	}
}
.casting_txt h6{
	margin-bottom: 0;
	color: #2118BC;
	font-family: "AktivGroteskEx-Bold", sans-serif;
}
.casting_sec ul li:hover{
	box-shadow: 0 9px 15px -9px rgba(0, 0, 0, 0.25);
	border-bottom: 1px solid #7b00a9;
	transition: 0.3s all ease-in-out;
}
.casting_sec ul li:before{
	content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -38px;
    margin: 0 auto;
    background: url(../images/arrow-down.png) no-repeat;
    width: 21px;
    height: 37px;
    background-size: contain;
    z-index: -1;
	opacity: 0;
}
.casting_sec ul li:hover:before{
	opacity: 1;
}
.everyuser_sec .getstarted_btm {
	margin-bottom: 133px;
}
.features_users{
	margin-bottom: 215px;
}
.features_usersmain{
	max-width: 1448px;
	margin-left: -30px;
	align-items: center;
	margin-bottom: 69px;
}
.features_users_head{
	max-width: 308px;
	
h4{
	font-size: 24px;
	letter-spacing: -1.01px;
	line-height: 34px;
	color: #ffffff;
	font-family: "AktivGroteskEx-Bold", sans-serif;
}
}
.features_users_slider, #features_users_slider, #features_users_slider1{
	width: calc(100% - 308px);
	padding-left: 61px;
}
.features_users_slider .slick-slide {
    margin-right: 46px;
}
.features_sliderbox{
	background-color: #040045;
	min-height: 162px;
	padding: 25px 20px;
	text-align: center;
    border-image: linear-gradient(0.25turn, rgba(242, 64, 209, 0.78), rgba(82, 152, 199, 0.78), rgba(0, 255, 183, 0.78)) 1;
    border-width: 1px;
    border-style: solid;
	
img{	
	width: 53px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 14px;
    height: 54px;
    object-fit: contain;
}
h6{
	color: #ffffff;
	letter-spacing: -0.76px;
	font-family: "AktivGroteskEx-Bold", sans-serif;
	margin: 0;
}
}

.features_users_slider .slick-prev, #features_users_slider .splide__arrow--prev, #features_users_slider1 .splide__arrow--prev {
	left: 60px;
    z-index: 9;
    top: auto;
    bottom: -100px;
    width: 78px;
    height: 50px;
    background-color: transparent;
    border: 1px solid #fff;
	opacity: 1;
	border-radius: 0;
}
.features_users_slider .slick-prev:before, #features_users_slider .splide__arrow--prev:before, #features_users_slider1 .splide__arrow--prev:before {
    content: "";
    position: absolute;
    width: 33px;
    height: 20px;
    background: url(../images/right-arrow.png);
    top: 50%;
    z-index: 999;
    background-size: cover;
    left: 36px;
    opacity: 1;
    transform: translate(-50%, -50%) rotate(180deg);
}
.features_users_slider .slick-next, #features_users_slider .splide__arrow--next, #features_users_slider1 .splide__arrow--next {
    left: 150px;
    z-index: 9;
    top: auto;
    bottom: -100px;
    width: 78px;
    height: 50px;
    background-color: transparent;
    border: 1px solid #fff;
	opacity: 1;
	border-radius: 0;
}
.features_users_slider .slick-next:before, #features_users_slider .splide__arrow--next:before, #features_users_slider1 .splide__arrow--next:before{
    content: "";
    position: absolute;
    width: 33px;
    height: 20px;
    background: url(../images/right-arrow.png);
    top: 50%;
    z-index: 999;
    background-size: cover;
    right: 0;
    opacity: 1;
    left: 0;
    margin: 0 auto;
    transform: translateY(-50%);
}
.my_screen .container{
	max-width: 1140px;
    position: relative;
}
.my_screen .study_smallimg{
	max-width: 212px;
}
.my_screen .study_boxslide {
    padding: 25px 0px;
    width: 77%;
    max-width: 77%;
    margin-left: 23%;
}
.my_screen .study_slidetitle h2 {
    font-size: 36px;
    line-height: 54px;
    font-weight: 700;
    letter-spacing: -1.51px;
	margin-left: -19px;
	margin-bottom: 5px;
}
.my_screen .study_slidetitle p {
    color: #ffffff;
	font-size: 19px;
}
.my_screen .study_smalltxt {
    margin-bottom: 0;
}
.my_screen .study_smalltxt h6{
	margin-bottom: 0;
}
.features_users.future_admin {
    margin-bottom: 0px;
	overflow: hidden;
}
.future_admin .features_users_slider.slick-slider .slick-track{
	margin-left: 0;
}
.future_admin .features_users_head h4{
	color: #040129;
}
.future_admin .features_sliderbox {
    background-color: #ffffff;
	display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.future_admin .features_sliderbox h6 {
    color: #040129;
}
.wpcf7-list-item {
    margin: 0;
}
.signup_page{
	padding: 80px 0px 160px;
}
.signup_form{
	max-width: 715px;
	margin: 0 auto;
	padding: 65px 65px;
	background-color: #030129;
}	
.signup_form h4{
	margin-bottom: 15px;
	color: #ffffff;
	font-family: "AktivGroteskEx-Bold", sans-serif;
}
.signup_form h1{
	color: #ffffff;
	margin-bottom: 14px;
	font-family: "AktivGroteskEx-Bold", sans-serif;
}
.signup_page input[type=submit] {
    background: #635ea9;
    border: 0;
    font-size: 18px;
    line-height: 28px;
    outline: 0;
    -webkit-appearance: none;
    border-radius: 50px;
    padding: 6px 12px;
    color: #ffffff;
    width: 152px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    font-weight: 700;
    margin-right: 0px;
}
.signup_page input[type=submit]:hover {
    background: #00bed6;
    transition: 0.3s all ease-in-out;
}
.signup_page label {
    color: #ffffff;
    font-size: 18px;
    position: relative;
    margin-bottom: 12px;

a{
	color: #ffffff;
	text-decoration: underline;
}
}
.signup_page .form-group {
    margin-bottom: 15px;
}
.signup_page .form-group input, .signup_page .form-group select {
    background-color: #ffffff;
    padding: 12px 15px;
    font-size: 16px;
    color: #040129;
    border-radius: 0;
}
.signup_page input[type=submit] {
    color: #ffffff;
    font-size: 18px;
    letter-spacing: -0.76px;
    opacity: 1;
    background-image: linear-gradient(90deg, rgb(160, 10, 132) 0%, rgb(57, 157, 224) 100%);
    margin-right: 20px;
    padding: 20px 35px 18px;
    border: 0;
    font-family: "AktivGroteskEx-Bold", sans-serif;
    -webkit-appearance: none;
    display: block;
    width: 100%;
	border-radius: 0;
	margin-top: 24px;
}
.signup_page input[type=submit]:hover {
	background: linear-gradient(90deg, rgb(160, 10, 132) 0%, rgb(57, 157, 224) 100%);
    box-shadow: 0 1px 13px rgba(0, 0, 0, 0.5) inset;
    transition: 0.3s all ease-in-out;
}
.signup_page2 .signup_form h4{
	margin-bottom: 37px;
}
.signup_page2 .signup_form .select-selected:after {
    top: 56px;
}
.signup_page2 .signup_form .form-group:nth-child(2){
	margin-bottom: 24px;
}
.login_page .submit_btn{
	margin-bottom: 32px;
}
.forget_pass{
	font-size: 17px;
	color: #ffffff;
	letter-spacing: 0.22px;
	font-weight: 500;
	text-align: center;
	
	a{
	color: #ffffff;
	text-decoration: underline;
	}
}
.signin_page .signup_form {
    padding: 65px 61px 34px;
}
.signin_page .signup_form h1{
	margin-bottom: 30px;
}
.signup_form form ul {
	padding: 0;
}
.signup_form form ul li{
	margin-bottom: 14px;
	display: block;
	font-size: 18px;
	color: #ffffff;
	text-align: center;
	position: relative;
}
.signin_page .signup_form ul li a, .signup_form form ul li a{
	width: 100%;
	text-align: center;
}
.signin_page .signup_form ul li a.blue_btn, .signup_form form ul li a.blue_btn{
	background-color: #2118BC;
	padding: 20px 8px 18px 85px;
	display: block;
	color: #ffffff;
	font-size: 18px;
	font-family: "AktivGroteskEx-Bold", sans-serif;
	letter-spacing: -0.76px;
	text-align: left;
}
.signin_page .signup_form ul li a.blue_btn span, .signup_form form ul li a.blue_btn span{
	display: inline-block;
	position: absolute;
	left: 8px;
	top: 8px;
	width: 50px;
	height: 50px;
	background-color: #ffffff;
	border-radius: 2px;
	display: flex;
	align-items:center;
	justify-content: center;
}
.signup_form form ul li:nth-child(4){
	margin-bottom: 36px;
}
.signup_form form ul li a.creatbtn{
    padding: 20px 35px 18px;
}
input.wpcf7-not-valid{
	border: 2px solid #FF0000!important;
}
.wpcf7-not-valid-tip {
    color: #FF0000 !important;
    font-size: 14px !important;
    display: block;
	letter-spacing: 0.18px;
	font-weight: 700 !important;
	margin-top: 5px;
}
.cust_def{
	margin-top: 140px;
	padding: 30px 0;
}
.archive .post {
	padding: 30px 0px 10px;
}
.post-template-default .post {
	padding-bottom: 0px;
}
.cust_page_content ul, .cust_page_content ol{
	padding-left: 15px;
}
.cust_page_content ul li, .cust_page_content ol li{
	font-size: 16px;
}
.post-thumbnail {
    margin: 10px 0px;
    display: block;
}

.site-main h3{
	margin-bottom: 10px;
}
.comment-form-cookies-consent input{
	width: auto;
    vertical-align: top;
    margin-top: 6px;
    margin-right: 5px;
}
.comment-form-cookies-consent label{
	width: 91%;
    vertical-align: top;
}
.comment-form p{
	margin-bottom: 10px;
}
.comment-form input[type=submit]{
	width: auto;
}
.comment-respond {
	margin: 10px 0;
}
iframe .logged-in .wpadminbar{
	display: none;
}
.wpcf7-not-valid-tip {
    font-size: 12px !important;
	line-height: 21px;
}
.comment-form input, input, input.form-control {
    padding: 12px 18px;
    background-color: #ffffff;
    font-size: 13px;
    color: #707070;
    font-weight: 300;
    border: 1px solid #E4E4E4;
    width: 100%;
    border-radius: 0;
}
.comment-form textarea, textarea, textarea.form-control {
    padding: 12px 18px;
    background-color: #ffffff;
    font-size: 13px;
    color: #707070;
    font-weight: 300;
    border: 1px solid #E4E4E4;
    height: 206px;
    font-family: "Poppins", sans-serif;
    width: 100%;
    border-radius: 0;
}
.comment-form .comment-form-cookies-consent input{
	width: auto;
}
.comment-form input[type=submit] {
    color: #ffffff;
    font-size: 18px;
    letter-spacing: -0.76px;
    opacity: 1;
    background-image: linear-gradient(90deg, rgb(160, 10, 132) 0%, rgb(57, 157, 224) 100%);
    margin-right: 20px;
    padding: 20px 35px 18px;
    border: 0;
    font-family: "AktivGroteskEx-Bold", sans-serif;
    -webkit-appearance: none;
    display: block;
    width: 100%;
    border-radius: 0;
    margin-top: 24px;
}
.comment-form input[type=submit]:hover {
    background: linear-gradient(90deg, rgb(160, 10, 132) 0%, rgb(57, 157, 224) 100%);
    box-shadow: 0 1px 13px rgba(0, 0, 0, 0.5) inset;
    transition: 0.3s all ease-in-out;
}
.comments-area{
	padding-bottom: 50px;
}
.no-results, .not-found{
	padding: 50px 0px;
}
.page-content p{
	margin-bottom: 10px;
}
.archive .post h2.entry-title {
    font-size: 28px;
}
.archive .post .post-thumbnail img{
	width: 100%;
	height: 200px;
	object-fit: cover;
}
.signup_form form ul{
	padding: 0;
}
.signup_form form ul li{
	display: block;
}
.page-id-2 .signup_form {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
    background-color: transparent;
}
.page-id-2 .signup_form h4 {
    color: #040129;
}

.industry_head em .breadcrumb{
	margin-bottom: 0;
}
h2.entry-title{
	margin-bottom: 10px;
}
.entry-summary{
	margin-bottom: 10px;
}
.countles_btn{
	text-align: center;
}
.countles_btn a.btn-gradient{
    padding: 13px 26px 10px;
}
.countles_btn a img{
    margin: 0;
    margin-right: 10px;
    width: 23px;
}
.worldwide_slider .swiper-wrapper{
    transition-timing-function: linear;
}
.splide__list{
	align-items: center;
}
#features_users_slider1 .splide__track {
    max-width: 766px;
}
#features_users_slider1 .splide__arrow--prev, #features_users_slider1 .splide__arrow--next{
	display: none;
}
.splide__arrow--prev svg {
    display: none;
}
.splide__arrow--next svg {
    display: none;
}