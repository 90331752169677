@media (min-width: 2530px) {
.conect_app {  width: 79.2%; }
}
@media (min-width: 1921px) {
.conect_app {  width: 79.2%; }
}
@media (max-width: 1839px) {
.sm_box4 {  top: 270px;}
.sm_box5 {  bottom: -35px; }
.getstarted_sec .imgTxt-sec-wrap .img-txt-row:nth-child(even) .img-sec:before { right: -105px; width: 610px; height: 495px; }
.getstarted_sec.advertiser_sec .imgTxt-sec-wrap:before, .getstarted_sec.administrator_sec .imgTxt-sec-wrap:before { right: 100px; top: -209px; }
}

@media (max-width: 1739px) {
.conect_app { width: 94.5%; }
}

@media (max-width: 1600px) {
.header .container, .industry_sec .container {  max-width: 97%; }
.industry_sec .industry_row .container {  max-width: 100%; }
.getstarted_sec .img-sec:before, .customer-sec .img-sec:before  { right: -80px; }
.sharing_list .row {  margin: 0px -32px; }
.conect_app {  width: 97.8%; }
.sm_box3 { left: 25px; }
.sm_box4 {  top: 245px; }
.sm_box5 { left: 200px;  bottom: -40px;}
.sm_box8 { left: 55px;}
.sm_box10 { bottom: -60px; right: 30px; }
.casting_sec ul li {   margin-right: 23px; }
.getstarted_sec.advertiser_sec .imgTxt-sec-wrap:before, .getstarted_sec.administrator_sec .imgTxt-sec-wrap:before {  top: -125px;}
.everyuser_sec .img-txt-row:nth-child(odd) .img-sec:after { right: -30px; }
.getstarted_sec .imgTxt-sec-wrap .img-txt-row:nth-child(even) .img-sec:before {  right: -65px;  width: 570px;  height: 470px;}
.card_box:before {  left: -65px;   width: 550px;  height: 550px;  }
.signage_sec .box-wrapper { max-width: 740px; }
}

@media (max-width: 1539px) {
.container {  max-width: 97%; }
h1 , .signage_sec .banner_title h1{ font-size: 40px;  line-height: 50px; }
.industry_row .text-section { margin-left: 47.9%;}
.industry_sec .text-section { width: 52%; max-width: 52%;}
.conect_slider .slick-slide { margin-right: 45px;}
.conect_app { width: 97.5%;}
.getstarted_sec .img-sec:before, .customer-sec .img-sec:before  { right: -35px; }
.getstarted_sec .imgTxt-sec-wrap:before { left: -5px; bottom: 30px; }
.advice_txt {   padding: 70px 49px; }
.countles_bg { width: 181px; height: 181px;}
.sm_box9 { left: 28px; }
.study_slider .slick-dots { left: -250px; }
.signage_sec .box-wrapper { max-width: 660px;}
.casting_sec ul li {  margin-right: 22px;}
.getstarted_sec.user_sec .img-txt-row:nth-child(even) .img-sec:after { right: -35px;  width: 310px; height: 310px; }
.getstarted_sec .imgTxt-sec-wrap .img-txt-row:nth-child(even) .img-sec:before { right: -25px;  width: 550px;}
.card_box:before { left: -100px;}
.card_box {  width: 32%; }
}

@media (max-width: 1439px) {
.customer-sec .container , .getstarted_head, .getstarted_sec .imgTxt-sec-wrap .container{  max-width: 97%;}
.img-txt-row .img-sec {  right: 15px; }
h1 , .signage_sec .banner_title h1{  font-size: 38px;  line-height: 48px; }
.industry_wrap .text-section h3 {  font-size: 36px; line-height: 46px;}
.blue_sec.blue_app .study_slidetitle h2 { font-size: 34px; line-height: 48px;}
.sharing_box .sharing_boximg { max-width: 90px; }
.sharing_box .sharing_boxtxt { width: calc(100% - 90px); }
.getstarted_sec .img-sec:before, .customer-sec .img-sec:before  { right: -15px; }
.advice_txt { padding: 70px 30px; }
.tdm_img { max-width: 880px; }
.sharing_list .row { margin: 0px -20px; }
.sharing_list .sharing_col { padding: 0px 20px; }
h4 { font-size: 21px; }
.countles_bg {  width: 164px;  height: 164px; padding: 25px 20px; }
.sm_box6 { right: 40px;}
.sm_box3 { left: 15px;  top: 190px; }
.sm_box4 { top: 250px;  right: 173px; }
.sm_box5 {  left: 140px;}
.countles_sm {  width: 145px; height: 145px;}
.countles_left h6, .countles_right h6 { font-size: 17px; }
.sm_box5 img { width: 54px;}
.countles_bg:before { width: 350px;  height: 350px; }
.study_slider .slick-dots {  left: -225px; 	}
.casting_sec ul li { margin-right: 20px;}
.everyuser_sec .img-txt-row:nth-child(odd) .img-sec:after { right: 0; width: 280px;  height: 280px;}
.getstarted_sec.advertiser_sec .imgTxt-sec-wrap:before{ top: -150px; width: 280px; height: 280px;}
.getstarted_sec.administrator_sec .imgTxt-sec-wrap:before {  top: -90px; width: 280px;  height: 280px;}
.getstarted_sec.user_sec .img-txt-row:nth-child(even) .img-sec:after { right: 0;top: -190px;}
.getstarted_sec .imgTxt-sec-wrap .img-txt-row:nth-child(even) .img-sec:before { right: 0;}
.user_sec .imgTxt-sec-wrap .img-txt-row:nth-child(even) .img-sec { right: 15px; }
.card_box:before {left: auto; right: -20px;}
#features_users_slider1 .splide__track {  max-width: 692px; }
}

@media (max-width: 1365px) {
.main_menu { padding-left: 35px; }
.header .header_main .navbar .nav-btm ul li { margin-right: 28px; }
.industry_sec .img-txt-row .img-sec {  max-width: 600px; }
.industry_sec .text-section { width: 54%; max-width: 54%;}
.industry_row .text-section { margin-left: 46.9%;}
.industry_sec .box-wrapper { padding-left: 90px;}
.study_boxslide{ width: 62%;  max-width: 62%;margin-left: 38%;}
.worldwide_logo{ width: 85px;  height: 85px;}
#worldwide_slide .splide__slide { width: 85px !important;  height: 85px !important; display: flex; align-items: center; }
.banner_inn { width: 55%; max-width: 55%; }
.responsive_tabs ul li a { font-size: 19px;  padding: 0px 22px; }
.responsive_tabs ul li.ui-tabs-active.ui-state-active { top: -5px; }
.tab_main { margin-left: 0; }
.study_boxslide { width: 58%; max-width: 58%;  margin-left: 42%;}
.study_slider .slick-dots { left: -120px;	bottom: 14px;}
.countles_bg { width: 150px; height: 150px;}
.countles_sm { width: 125px;  height: 125px;  padding: 20px 15px;}
.countles_left h6, .countles_right h6 {  font-size: 16px;}
.countles_sm img {  width: 45px;}
.sm_box10 { right: 15px;}
.header {  background-position: center top; }
.signage_sec .box-wrapper { padding-left: 0 !important;    max-width: 585px;}
.casting_sec ul li { margin-right: 19px;}
.casting_img {  width: 85px;  height: 85px;}
.features_usersmain {  margin-left: 0; }
.text-section ul li.btn {  padding: 13px 30px 11px; }
.card_box { width: 31.94%; }
#features_users_slider1 .splide__track {
    max-width: 608px;
}
}

@media (max-width: 1279px) {
.header .header_main .logo {  max-width: 215px; }
.main_menu { width: calc(100% - 215px);  }
.header .header_main .navbar .nav-btm ul li {  font-size: 20px; }
.industry_row .text-section {  margin-left: 44%;}
.industry_sec .text-section { width: 56%; max-width: 56%;}
h1{ font-size: 35px; line-height: 45px;}
.industry_sec .text-section p { font-size: 20px;}
.industry_sec .box-wrapper { padding-left: 63px;}
.industry_wrap .text-section h3 { font-size: 34px; line-height: 44px;}
.industry_sec .img-txt-row {  margin-bottom: 80px;}
.sharing_list { padding: 120px 0px 120px;}
.sharing_list .row { margin: 0px -25px;}
.sharing_list .sharing_col { padding: 0px 25px;}
.sharing_box .sharing_boximg { max-width: 90px;}
.sharing_box .sharing_boxtxt { width: calc(100% - 90px);}
.industry_sec .img-txt-row .img-sec { max-width: 515px;}
.study_smallimg {  max-width: 380px; }
.img-txt-row .img-sec , .industry_wrap .img-txt-row .img-sec, .tab_content .tab_slider, .tab_content .tab_img { max-width: 470px; }
.text-section h3 {  font-size: 32px; line-height: 42px; }
.tdm_img {  max-width: 820px; }
.tdm_txt { padding: 54px 40px;  max-width: 590px; }
.video_box:before { width: 1220px; height: 970px;}
.text-section ul li.btn {  padding: 13px 30px 11px;}
.banner_inn { width: 58%; max-width: 58%; }
.responsive_tabs ul li a { font-size: 18px; padding: 0px 18px;}
.featured_client {  margin-bottom: 125px;}
.featured_img1 { top: 61px; width: 270px; height: 203px;}
.featured_img2 {right: 23px; width: 230px; height: 265px;}
.featured_img3 { left: 160px; top: 295px;}
.featured_img4 { right: 82px;top: 295px; width: 177px;  height: 207px;}
.featured_img5 { right: -252px; top: 295px; width: 326px; height: 207px;}
.tab_content { width: 54%; max-width: 54%; padding-right: 15px; margin-left: 48.1%;  }
.countles_big { max-width: 500px;}
.sm_box8 { top: 160px;}
.sm_box4 { top: 235px; right: 20px; }
.footer .footer_btm .footer_btmright ul li, .footer_btmright h6 {  margin-right: 22px; }
 h2 , .tab_title h2, .study_slidetitle h2, .video_title h2,  .countles_sec .countles_title h2{ font-size: 42px; }
.study_slider .slick-dots { left: -88px; }
.banner_inn .banner_rightimg {  max-width: 436px; }
.conect_app .conect_title h2 {  font-size: 36px; }
.study_main { padding-top: 70px; }
.featured_txt { width: calc(100% - 540px);}
.featured_img {  width: 540px; }
.card_box:before { right: -20px; }
.allfeatures_sec {  margin-bottom: 120px;}
.casting_sec ul li {  margin-right: 18px;}
.features_users_slider { padding-left: 20px;}
.features_users_slider .slick-slide {  margin-right: 30px;}
.getstarted_sec.administrator_sec .imgTxt-sec-wrap:before {  top: -50px; right: 35px;}
.card_box { width: 31.81%; }
}

@media (max-width: 1179px) {
.header .container, .industry_sec .container, .container, .my_screen .container {  max-width: 97%; }
.header .header_main .navbar .nav-btm ul li { font-size: 18px; margin-right: 20px; }
.footer .footer_btm .footer_btmright ul li, .footer_btmright h6 {  margin-right: 11px; }
.header .header_main .navbar .nav-btm ul li.free-btn {  margin-right: 15px;    padding: 13px 20px 11px; }
.industry_sec .img-txt-row .img-sec {  max-width: 480px; }
.industry_row .text-section {  margin-left: 45%; }
h1 { font-size: 32px;  line-height: 42px;}
.industry_sec .text-section p { font-size: 18px;  line-height: 27px;}
.industry_wrap .text-section {  width: 53%; max-width: 53%;}
.text-section ul li.btn { padding: 13px 22px 11px; }
.conect_slider .slick-slide {  margin-right: 30px;}
.sharing_list .row { margin: 0px -12px;}
.sharing_box .sharing_boxtxt { padding-left: 20px;}
.study_boxslide { margin-left: 42%;}
.worldwide_logo { width: 75px;  height: 75px;}
#worldwide_slide .splide__slide { width: 75px !important;  height: 75px !important; }
.text-section { width: 52%; max-width: 52%;}
.img-txt-row { margin-bottom: 130px; }
.imgTxt-sec-wrap .img-txt-row:nth-child(even) .text-section { margin-left: 48.1%;}
.industry_wrap .img-txt-row { padding: 100px 0px;}
.video_title h2, h2, .tab_title h2, .countles_sec .countles_title h2 { font-size: 32px; line-height: 44px;}
.text-section ul li.btn.btn-border { padding: 11px 16px 9px; }
.tdm_img { max-width: 745px;}
.sharing_list .sharing_col {padding: 0px 15px;}
.sharing_box .sharing_boxtxt h4 { font-size: 20px; }
.blue_app .conect_app { padding-bottom: 70px;}
.video_box:before { width: 1180px; }
.banner_sec .banner_button ul li.free-btn { padding: 13px 20px 11px; }
.banner_sec .banner_button ul li select {  padding: 11px 60px 10px 25px; }
.banner_inn .banner_rightimg {  max-width: 380px;}
.countles_big { max-width: 430px;}
.countles_bg { width: 140px; height: 140px;}
.sm_box8 {  top: 130px;}
.sm_box3 { top: 160px;}
.sm_box4 {  top: 195px;}
.sm_box7 { top: 185px;}
.sm_box8 {  left: 45px;}
.featured_img1 { top: 61px;width: 208px;  height: 159px;}
.featured_img2 {  right: 23px;  width: 190px; height: 220px;}
.featured_img3 { left: 85px; top: 295px;}
.featured_img4 { right: 75px; top: 295px; width: 155px; height: 178px;}
.featured_img5 { right: -252px;  top: 295px; width: 316px; height: 205px;}
.featured_slider .slick-dots { left: 41px; }
.img-txt-row .img-sec, .industry_wrap .img-txt-row .img-sec, .tab_content .tab_slider, .tab_content .tab_img { max-width: 420px;}
.responsive_tabs ul.tablist {  width: 120%;  justify-content: flex-start; }
.featured_img { width: 435px;}
.featured_txt {  width: calc(100% - 435px);}
.casting_sec ul li { margin-right: 16px;}
.getstarted_sec.advertiser_sec .imgTxt-sec-wrap:before {  top: -85px; right: 40px;}
.my_screen .study_slidetitle h2 {  font-size: 30px;	line-height: 40px;}
.my_screen .study_boxslide {  width: 75%; max-width: 75%;  margin-left: 25%; }
.card_box {
    width: 31.66%;
}
}

@media (max-width: 1079px) {
.header .header_main .navbar .nav-btm ul li {  font-size: 16px; }
.header .header_main .navbar .nav-btm ul li.free-btn {  padding: 12px 16px 9px; }
.header .header_main .navbar .nav-btm ul li.free-btn a img { margin-left: 11px; width: 19px; }
.header .header_main .navbar .nav-btm ul li.login-btn { padding: 9px 13px 7px 18px; }
.header .header_main .navbar .nav-btm ul li.login-btn a img { margin-left: 12px; width: 19px; }
.footer .footer_btm .footer_btmleft ul li { margin-right: 20px; }
.footer .footer_btm .footer_btmright ul li, .footer_btmright h6 { margin-right: 11px; font-size: 15px; }
.main { margin-top: 137px; }
.industry_sec .img-txt-row .img-sec { max-width: 445px;}
h1 { font-size: 30px;  line-height: 40px;}
.text-section ul li.btn { color: #ffffff; font-size: 16px;}
.img-txt-row .img-sec, .industry_wrap .img-txt-row .img-sec { max-width: 440px;}
.sharing_list .sharing_col { padding: 0px 15px;}
.study_smallimg {  max-width: 340px;}
.blue_sec.blue_app { padding: 60px 0px 80px;}
.blue_sec.blue_app .study_slidetitle h2 , .video_title h2, h2, .tab_title h2, .countles_sec .countles_title h2{ font-size: 30px;  line-height: 42px;}
.blue_sec.blue_app .study_main { padding-top: 0;}
.img-txt-row, .industry_imgTxt.imgTxt-sec-wrap .img-txt-row:nth-child(even) { margin-bottom: 100px;}
.industry_worldsec .worldwide_slider { margin-bottom: 100px;}
.sharing_list .sharing_col { flex: 0 0 auto; width: 50%;     margin-bottom: 35px; }
.sharing_list .sharing_col:last-child { margin-bottom: 0px;}
.getstarted_sec .imgTxt-sec-wrap:before {  width: 320px;  height: 320px; }
.advice_txt h3 br { display: none; }
.advice_txt h3 {  font-size: 28px;  }
.getstarted_sec .imgTxt-sec-wrap .text-section h3 {  font-size: 26px; line-height: 36px; }
.getstarted_head {  margin-bottom: 60px; }
.video_box:before { width: 980px; }
.industry_sec .img-txt-row { margin-bottom: 50px;}
.blue_sec {  padding-bottom: 70px; }
.responsive_tabs {  padding: 110px 0px 30px;}
.responsive_tabs ul.tablist { margin-bottom: 90px;}
.countles_bg {  width: 120px;  height: 120px;}
.countles_sm { width: 110px;  height: 110px;}
.countles_left h6, .countles_right h6 { font-size: 15px;}
.countles_bg img, .countles_sm img {  width: 45px; }
.sm_box8 { left: 30px; top: 109px; }
.sm_box9 { left: 16px; }
.sm_box3 { top: 150px;  left: 8px; }
.sm_box4 { top: 190px; }
.sm_box5 { bottom: -20px; }
.featured_img2 {  width: 150px; height: 170px;     right: 23px;}
.featured_img1 {  width: 168px; height: 108px; }
.featured_img3 { top: 295px; width: 100px; height: 100px; left: 0; }
.featured_img4 { right: 105px;  width: 146px;  height: 165px; }
.featured_img5 { right: -185px;   width: 280px; height: 165px; }
.featured_txt { max-height: 395px; }
.featured_client:before {  height: 395px; } 
.footer .footer_btm .footer_btmright ul li, .footer_btmright h6 { margin-right: 8px; }
.footer .footer_btm .footer_btmleft ul li { margin-right: 15px; }
.footer .footer_btm .footer_btmright ul li a img{ width: 32px; }
.footer .footer_btm .footer_btmright ul li:nth-child(3) a img{ width: 28px; }
.conect_app {  padding: 70px 0px 50px; }
.countles_sec {  padding: 120px 0px 60px; }
.featured_box {  min-height: 460px; }
.featured_slider .slick-dots { left: -8px; bottom: 195px; }
.study_main { padding-top: 50px; }
.banner_sec .banner_button ul li.free-btn { padding: 13px 15px 11px; margin-right: 0;}
.banner_sec .banner_button ul li:nth-child(2) { padding: 0px 13px;}
.banner_sec .banner_button ul li select {  padding: 11px 53px 10px 25px;}
.study_boxslide {  width: 56%; max-width: 56%; margin-left: 44%;}
h2, .tab_title h2, .study_slidetitle h2, .video_title h2, .countles_sec .countles_title h2 { font-size: 36px; line-height: 48px;}
.featured_img {  width: 360px;}
.featured_txt { width: calc(100% - 360px);}
.img-txt-row .img-sec, .industry_wrap .img-txt-row .img-sec, .tab_content .tab_slider, .tab_content .tab_img {  max-width: 405px;}
.casting_sec ul li {  margin-right: 15px; padding-bottom: 35px;}
.getstarted_sec.administrator_sec .imgTxt-sec-wrap:before {  top: 42px;}
.administrator_sec {  padding: 85px 0 110px;}
.my_screen .study_boxslide {  width: 72%;  max-width: 72%;  margin-left: 28%;}
.getstarted_sec.user_sec .imgTxt-sec-wrap .img-txt-row { margin-bottom: 60px; }
.getstarted_sec.user_sec .img-txt-row:nth-child(even) .img-sec:after { right: 0; top: -120px;  width: 240px;  height: 240px;}
.getstarted_sec.user_sec .imgTxt-sec-wrap .img-txt-row:nth-child(even) { margin-bottom: 30px;}
.advertiser_sec  { padding: 100px 0px; }
.getstarted_sec.advertiser_sec .imgTxt-sec-wrap:before {  width: 220px; height: 220px;}
.card_top img {  width: 55px;}
.card_top h4 { width: calc(100% - 55px);}
.advertiser_sec .getstarted_head {  margin-bottom: 50px;}
.casting_img { width: 70px; height: 70px;}
h1, .signage_sec .banner_title h1 { font-size: 36px;line-height: 46px;}
.card_box {
    width: 31.5%;
}
}

@media (max-width: 1024px) {
.header .header_main .navbar .nav-btm ul li.login-btn {  padding: 10px 18px 7px 18px; }
.header .header_main .navbar .nav-btm ul li.free-btn a img , .header .header_main .navbar .nav-btm ul li.login-btn a img { display: none; }
}

@media (max-width: 991px) {
body.open { overflow: hidden; }
body.open #wrapper:before{ opacity: 1; visibility: visible; z-index: 9; }
.nav-icon { display: block; z-index: 99; } 
.header .header_main .navbar { display: none; position: fixed;  top: 93px; left: 0; width: 100%; right: 0; float: none; z-index: 99; background: #040129;
 transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out;
 -o-transition: all 0.3s ease-in-out; padding: 20px 35px; overflow: auto; max-height: calc(100vh - 93px); max-height: -moz-calc(100vh - 93px);  }
.open .header { background-color: #002F3F; }
.open .header .header_main .navbar { display: block;  }
.header .header_main .main_menu nav ul { display: block; }
.header .header_main .navbar ul li { display: block; margin: 0; margin-bottom: 15px;  text-align: center; }
.header .header_main .navbar ul li.btn { margin-bottom: 0px; width: 133px; margin: 0 auto;  }
.click_menu { display: block;}
.header .header_main .main_menu nav ul li.open2 ul.sub-menu{ display: block; opacity: 1; visibility: visible; position: relative; width: 100%; padding: 20px 15px 10px;
background-color: transparent;}
.header .header_main .main_menu nav ul.sub-menu li { padding-bottom: 0px; }
.header .header_main .main_menu nav ul.sub-menu li a {  color: #fff; font-size: 14px;}
.open2 > .click_menu {transform: rotate(-135deg);top: 9px;}
.footer .footer_btm .footer_btmleft ul { flex-wrap: wrap; }
.footer .footer_btm .footer_btmright ul li a img { width: 25px; }
.footer-maintop .foot-1 {  width: 36%; }
.footer-maintop .foot-1:last-child {  width: 20%; }
.header .header_main .navbar .nav-top ul li a, .header .header_main .navbar .nav-btm ul li a { color: #fff !important; }
.nav-btm { display: block !important; }
.header .header_main .navbar .nav-top { display: none; }
.header .header_main .navbar .nav-btm .nav-top { display: block; }
.header .header_main .navbar .nav-btm ul , .header .header_main .navbar .nav-btm ul li{ display: block;  text-align: left; font-size: 20px; margin-right: 0;  margin-bottom: 26px; }
.header .header_main .navbar .nav-btm .nav-top ul li { font-size: 16px; font-weight: 500;    font-family: "DM Sans", sans-serif; margin-bottom: 15px; }
.right_button ul { max-width: 277px; }
.header .header_main .navbar .nav-btm ul li.free-btn , .header .header_main .navbar .nav-btm ul li.login-btn { display: block; width: 100%;font-size: 18px;    text-align: center;  }
.header .header_main .navbar .nav-btm ul li.free-btn a img, .header .header_main .navbar .nav-btm ul li.login-btn a img { display: inline-block; }
.header .header_main .navbar .nav-btm ul li.free-btn a svg, .header .header_main .navbar .nav-btm ul li.login-btn a svg { display: inline-block; margin-left: 10px;}
.header .header_main .navbar .nav-btm ul li.login-btn { border: 2px solid #fff; }
.main { margin-top: 93px;}
.worldwide_logo { width: 54px;  height: 54px;  margin-right: 50px;}
#worldwide_slide .splide__slide { width: 54px !important;  height: 54px !important;  margin-right: 50px !important; }
.conect_app .conect_title { max-width: 310px;}
.study_smallimg { max-width: 300px;}
.study_boxslide { width: 52%; max-width: 52%;  margin-left: 48%; padding: 40px 0px 70px; }
.blue_sec.blue_app {  padding: 30px 0px;}
.conect_slider .slick-next , #conect_slider .splide__arrow--next{  right: 0;}
.img-txt-row .img-sec, .industry_wrap .img-txt-row .img-sec { max-width: 335px;}
.text-section ul { flex-wrap: wrap;  margin-bottom: 0;}
.text-section ul li.btn {  margin-right: 0; margin-bottom: 15px;}
.sharing_list {  padding: 55px 0px;}
.conect_app {  padding: 80px 0px;}
.conect_app .conect_title h2 {  font-size: 34px;}
.industry_worldsec { padding: 100px 0px;}
.text-section h3 { font-size: 28px;  line-height: 38px;}
.img-txt-row, .industry_imgTxt.imgTxt-sec-wrap .img-txt-row:nth-child(even) { margin-bottom: 0;}
.img-txt-row {  padding: 40px 0px 0px; }
.imgTxt-sec-wrap .img-txt-row:nth-child(even) {  padding: 65px 0px 40px;}
.img-txt-row:before, .imgTxt-sec-wrap .img-txt-row:nth-child(even):before { width: 46.7%; }
.reseller_head { margin-bottom: 40px;}
.tdm_txt { padding: 35px 35px; max-width: 509px; }
.sharing_list.reseler_list { padding: 80px 0px 120px;}
.reseller_signange {  padding: 70px 0px 100px;}
.advice_sec {  margin-bottom: 80px;}
.getstarted_sec .imgTxt-sec-wrap .img-txt-row { margin-bottom: 70px;}
.getstarted_sec .imgTxt-sec-wrap .img-txt-row:nth-child(even) {  margin-bottom: 70px;}
.video_box:before {  width: 850px;  height: 802px; left: -133px; }
.footer .footer-top ul li { font-size: 15px; }
.footer .footer-top h5 {  font-size: 18px; }
.featured_img {  width: 100%; max-width: 340px;  min-height: 425px;  margin: 0 auto;}
.featured_txt { width: 100%;  max-width: 100%;  max-height: 100%;    padding: 30px 20px;}
.featured_client:before{ width: 100%;  }
.featured_box { min-height: 295px;}
.featured_slider .slick-dots { left: 0px; bottom: -55px;  right: 0;}
.responsive_tabs ul.tablist { width: 155%;}
.tab_content { width: 100%; max-width: 100%;  padding-right: 0;  margin-left: 0;}
.tab_content .tab_slider, .tab_content .tab_img { display: block; position: relative;  width: 100%; max-width: 544px; top: 0; transform: inherit; text-align: left; left: 0;}
.responsive_tabs ul.tablist {  margin-bottom: 55px;}
.tab_content ul.tabs_ul {  padding-top: 30px;}
.countles_big {  max-width: 300px;}
.countles_box .countles_txt .countles_txtimg {  max-width: 45px;}
.countles_box .countles_txt .countles_txthead { width: calc(100% - 45px); padding-left: 20px;}
.countles_box .countles_txt .countles_txthead h6 { font-size: 16px; }
.countles_box .countles_txt .countles_txthead p { font-size: 15px; }
.countles_bg { width: 110px; height: 110px;  padding: 20px 10px;}
.countles_sm { width: 100px; height: 100px; padding: 20px 10px;}
.countles_bg img, .countles_sm img { width: 40px; margin-bottom: 10px;}
.sm_box2 { right: 25px;}
.sm_box3 { top: 120px; left: 0px;}
.sm_box4 { top: 150px; right: 9px;}
.sm_box5 { bottom: -45px; left: 100px;}
.sm_box6 { right: 15px;}
.sm_box7 {  top: 138px; }
.sm_box8 { left: 20px; top: 80px;}
.sm_box9 {  left: 10px;  bottom: 22px;}
.sm_box10 {  bottom: -38px;  right: -5px;}
.countles_main {  padding: 50px 0 155px; }
.countles_bg:before {  width: 200px; height: 200px;  left: -53px;  top: -25px;}
.countles_sm:before {  width: 200px; height: 200px; }
.countles_big:before {  left: -75px; top: -45px; width: 475px;  height: 475px;}
.featured_img1 {  width: 182px;  height: 128px; top: 52px;}
.featured_img2 { width: 140px;  height: 160px;  top: 20px; right: 0;}
.featured_img3 {  top: 200px; left: 0; top: 195px;}
.featured_img4 { right: 0;  width: 200px;  height: 228px; top: 195px;}
.featured_img5 {display: none;}
.featured_client {  margin-bottom: 85px;}
.banner_sec {  padding-top: 25px;}
.banner_inn .banner_rightimg {  max-width: 280px; }
.banner_sec .banner_button ul li { margin-bottom: 10px; }
h2, .tab_title h2, .study_slidetitle h2, .video_title h2, .countles_sec .countles_title h2 { font-size: 34px;  line-height: 44px;}
.banner_inn { padding: 55px 0px; }
h1, .signage_sec .banner_title h1 {  font-size: 32px; line-height: 41px; }
.casting_sec ul li { margin-right: 11px;}
.casting_txt h6{ font-size: 17px; }
.my_screen .study_boxslide { width: 62%;  max-width: 62%;  margin-left: 38%; }
.my_screen .study_slidetitle h2 { margin-left: 0; }
.getstarted_sec.user_sec .img-txt-row:nth-child(odd) .img-sec:after { width: 250px; height: 250px;}
.user_sec .imgTxt-sec-wrap .img-txt-row:nth-child(odd) .text-section { margin-left: 48.1%; }
.card_box {  padding: 25px 21px;}
.card_top img { width: 48px; }
.card_top h4 { width: calc(100% - 48px);  margin-left: 15px;  font-size: 18px; }
.card_txt p { font-size: 16px; }
.freedemo_sec .col-lg-6 { margin-bottom: 20px;}
.header .header_main .navbar .nav-btm .right_button ul li:last-child.free-btn {  border: 2px solid #fff; }
.header .header_main .navbar .nav-btm .right_button ul li:last-child.free-btn a {  color: #fff; }
header .header_main .navbar .nav-btm .right_button ul li:last-child.free-btn a svg g { fill: #fff;}
.card_box {  width: 30.94%;}
.features_users_slider, #features_users_slider, #features_users_slider1 { padding-left: 20px; }
}

/*@media (min-width: 768px){
.card-columns { -webkit-column-count: 3; column-count: 3; -webkit-column-gap: 25px; column-gap: 25px;}
.card-columns .card_box { display: inline-block; width: 100%;}
}*/

@media (max-width: 767px) {
.footer-maintop .foot-1 , .footer-maintop .foot-1:last-child{ width: 100%; }
.footer-maintop { flex-wrap: wrap; }
.footer .footer_btm .footer_btmright ul li a img {  width: 28px;}
.footer .footer_btm .footer_btmright ul li:nth-child(3) a img { width: 26px;}
.footer .footer_btm .footer_btmright ul li.login {  padding: 10px 19px 7px;}
.footer_btmright h6{ display: none; }
.footer-maintop .foot-1 .innfoot-1 { margin-top: 0;}
.foot-1 h5 { position: relative;}
.foot-1 h5:after {  border-bottom-style: solid;  border-bottom-width: 2px; border-right-style: solid;  border-right-width: 2px; content: "";  display: inline-block; height: 10px;
 right: 3px; position: absolute; top: 3px; width: 10px; border-color: #040129; transform: rotate(45deg); }
.foot-1.active h5:after { -ms-transform: rotate(225deg);  -webkit-transform: rotate(225deg);  transform: rotate(225deg); top: 9px;}
.foot-1 ul { display: none; padding-bottom: 16px;}
.foot-1.active {margin-bottom: 23px;}
.foot-1.active ul { display: block;}
.footer-maintop .foot-1:nth-child(4).active ul{margin-bottom: 21px;}
.foot-1.active .innfoot-1 h5:after{transform: rotate(45deg);}
.foot-1.active .innfoot-1 ul{display: none;}
.foot-1 .innfoot-1.active ul{display: block;}
.foot-1 .innfoot-1.active h5:after { -ms-transform: rotate(225deg); -webkit-transform: rotate(225deg);  transform: rotate(225deg);top: 9px;}
.industry_sec .img-txt-row .img-sec { max-width: 328px; position: relative; transform: inherit; top: 0; width: 100%; left: 0; margin: 0 auto; margin-bottom: 15px; text-align: center;
 padding: 0; display: block;}
.industry_sec .industry_head {  display: none;}
.industry_sec .img-txt-row, .industry_wrap .img-txt-row{ padding-top: 0; }
.industry_row .text-section, .industry_wrap .text-section { margin-left: 0;  width: 100%; max-width: 100%;  padding: 0;}
.industry_sec .box-wrapper {  padding-left: 0;}
h1 { font-size: 24px; line-height: 34px; letter-spacing: -1.01px;}
.industry_sec .text-section p, .sharing_box .sharing_boxtxt { font-size: 15px;  line-height: 24px;  letter-spacing: 0.19px; }
.text-section ul li.btn { width: 100%;}
.industry_sec .text-section ul {  margin-bottom: 0;}
.industry_sec .img-txt-row { padding-bottom: 0;}
.img-txt-row:before { display: none; }
.img-txt-row .img-sec, .industry_wrap .img-txt-row .img-sec { max-width: 300px; position: relative;  transform: inherit; top: 0; width: 100%; left: 0; margin: 0 auto;
 margin-bottom: 15px; text-align: center;  padding: 0; display: block;}
.industry_wrap .text-section h3 { font-size: 20px; line-height: 30px;}
.text-section p { font-size: 14px; line-height: 20px; letter-spacing: 0.18px; }
.industry_wrap .img-txt-row {  padding-bottom: 30px; margin-bottom: 0px;}
.sharing_list { padding: 30px 0px 80px;}
.sharing_box .sharing_boximg {  max-width: 80px;    width: 80px;}
.sharing_box .sharing_boxtxt { width: calc(100% - 80px);}
h4 {  margin: 0; font-size: 18px;}
.conect_app .conect_title { max-width: 90%;  width: 100%; text-align: center; margin: 0 auto; margin-bottom: 45px;}
.conect_app .conect_title h2 { font-size: 24px;}
.conect_app .conect_title h2 br { display: none; }
.conect_app .conect_title p { font-size: 15px; line-height: 22px; letter-spacing: 0.19px;}
.conect_app { width: 100%;  padding-top: 50px;  padding-bottom: 10px;}
.conect_slider, #conect_slider { margin-left: 0; width: 100%;  margin-bottom: 45px;}
.conect_slider .slick-next, #conect_slider .splide__arrow--next{ display: none !important; }
.conect_txt p { font-size: 14px;line-height: 20px;}
.conect_button { text-align: center; }
.conect_button a.btn-border {font-size: 16px; }
.study_smallimg {max-width: 170px;  position: relative; top: 0; transform: inherit;}
.study_boxslide { width: 100%; max-width: 100%; margin-left: 0;  padding: 30px 0px;}
.blue_sec.blue_app .study_slidetitle h2 { font-size: 24px; line-height: 34px;}
.study_main { padding-top: 20px;}
.study_smallimg span { top: -15px;}
.study_smallimg span img { width: 38px;}
.study_smalltxt { display: flex;     flex-direction: row-reverse; align-items: center; }
.study_smalltxt h6 { margin-left: 23px; margin-bottom: 0; width: calc(100% - 170px); font-size: 15px; letter-spacing: -0.63px; }
.study_boxslide blockquote { font-size: 14px; letter-spacing: 0.18px; }
.industry_worldsec { padding: 80px 0px 0px;}
.worldwide_title h3 { font-size: 20px; letter-spacing: -0.84px;}
.industry_worldsec .worldwide_slider { margin-bottom: 80px;}
.video_title h2 { font-size: 24px; line-height: 36px;  letter-spacing: -1.01px;}
.video_box a.video_btn { width: 60px; height: 60px;}
.text-section { width: 100%; max-width: 100%; padding-right: 0;}
.imgTxt-sec-wrap .img-txt-row:nth-child(even) .text-section {  margin-left: 0;}
.text-section h3 { font-size: 20px; line-height: 30px;  letter-spacing: -0.84px;}
.video_box {  max-width: 90%;}
.footer .footer_btm .footer_btmleft ul li a {  color: #fff;}
.sharing_list .sharing_col {  padding: 0px 12px;}
.conect_button a img {  margin-right: 10px; width: 20px;}
.reseller_sec {  padding: 40px 0 0;}
.reseller_head {  margin-bottom: 25px;}
.tdm_img {  margin-bottom: 53px;}
.tdm_txt { max-width: 100%; position: relative;  top: 0; transform: inherit; }
.tdm_txt p { font-size: 15px; line-height: 26px; margin-bottom: 20px;}
.sharing_list.reseler_list { padding: 40px 0px 80px;}
.sharing_list .sharing_col {  width: 100%;}
.getstarted_sec { padding: 55px 0; }
.getstarted_head { margin-bottom: 35px;  padding-bottom: 35px;}
.getstarted_sec .imgTxt-sec-wrap .img-txt-row {  margin-bottom: 60px; padding-top: 0; }
.getstarted_sec .imgTxt-sec-wrap:before, .getstarted_sec .img-sec:before, .video_box:before , .customer-sec .img-sec:before  { display: none; }
.getstarted_sec .imgTxt-sec-wrap .img-txt-row:nth-child(even) { margin-bottom: 0;}
.reseller_signange {  padding: 55px 0px 55px; }
.reseller_formhead { margin-bottom: 30px; }
.reseller_formhead p {font-size: 16px; line-height:22px;}
.reseller_formhead h1 {  margin-bottom: 30px; }
.reseller_form {  padding: 30px 35px; }
.form-group.input_check label:before { width: 24px; height: 24px; }
.form-group.input_check input[type=checkbox]:checked + label:after { left: 9px; }
.getstarted_sec .imgTxt-sec-wrap .text-section h3 { font-size: 20px; line-height: 30px;}
.advice_img { width: 100%;}
.advice_txt { width: 100%;}
.advice_txt { padding: 30px 25px;}
.advice_txt h3 {  font-size: 20px;margin-bottom: 20px;}
.advice_txt p {  font-size: 14px; line-height: 20px; letter-spacing: 0.18px;}
.blue_app .conect_app { padding-bottom: 0;}
.blue_sec.blue_app .study_slider .slick-list { padding-top: 25px;}
.signup_form , .signin_page .signup_form{  padding: 28px 28px;	}
.signup_page input[type=submit] {  font-size: 15px;  padding: 14px 15px 11px;     white-space: break-spaces;}
.forget_pass p{ font-size: 15px; line-height: 24px;    text-transform: capitalize; }
.signup_page label { font-size: 16px;}
.select-selected { font-size: 16px;}
.signin_page .signup_form ul li a.blue_btn { font-size: 16px; }
.signin_page .signup_form ul li a.creatbtn { padding: 16px 20px 15px;}
.footer .footer_btm .footer_btmright ul li, .footer_btmright h6 {  margin-right: 13px;}
.countles_big, .countles_right{ display: none; }
.countles_bg { width: 155px;  height: 155px;}
.countles_sm {  width: 155px; height: 155px;}
.countles_bg img, .countles_sm img {  width: 48px;}
.countles_sec {  padding: 60px 0px;}
.countles_left { width: 100%; min-height: 300px;}
.countles_sm:before { left: -35px;  top: -28px;}
.countles_lg:before { left: -35px; top: -28px;}
.sm_box1 {  left: 0; right: 0;  margin: 0 auto;}
.sm_box4 { top: 190px; right: 91px;}
.sm_box5 { left: 205px;}
.banner_inn { width: 100%; max-width: 100%; padding: 30px 0px; }
.banner_sec .banner_txtimg { display: flex;}
.banner_inn .banner_rightimg {   max-width: 100%;  transform: inherit;  width: 40%;  max-width: 40%; position: relative;}
.banner_sec .banner_txtimg p { font-size: 15px; line-height: 24px; font-weight: 500; letter-spacing: 0.19px; padding-right: 10px; width: 60%;}
.banner_sec .banner_button ul { margin-bottom: 13px;}
.banner_sec .banner_button ul li { width: 100%; text-align: center;}
.banner_sec .banner_button ul li select { width: 100%;}
h4 , .sharing_box .sharing_boxtxt h4 {  font-size: 17px;margin: 0;}
.responsive_tabs {  padding: 60px 0px 30px;}
.responsive_tabs ul.tablist:before{ display: none;}
.platform_title {  margin-bottom: 35px;}
.responsive_tabs ul li a { font-size: 15px;  padding: 0px 15px;}
.responsive_tabs ul li.ui-tabs-active.ui-state-active a {  font-size: 21px;}
.tab_title h2 { font-size: 22px; }
.tab_content ul.tabs_ul li { display: block;  font-size: 14px; line-height: 28px; letter-spacing: 0.18px;}
.tab_content ul.tabs_ul li:before { width: 19px; height: 15px;}
a.btn-gradient { font-size: 16px;}
.countles_main { padding: 50px 0 80px;}
.featured_txt h3 { font-size: 22px; text-align: center;}
.featured_txt p { font-size: 14px; line-height: 25px;}
.text-section ul {  justify-content: center; }
.countles_title h2{ font-size: 24px; }
.countles_sec .countles_title h6 { font-size: 15px;  }
.worldwide_sec {  margin-bottom: 50px;}
h2, .tab_title h2, .study_slidetitle h2, .video_title h2, .countles_sec .countles_title h2{  font-size: 22px; line-height: 32px; }
.banner_sec .banner_button em a { display: inline-block; }
.signage_sec .box-wrapper {  max-width: 100%;  padding-right: 0;}
.signage_sec .img-txt-row{ padding-bottom: 50px;}
.casting_sec h4 { margin-bottom: 25px;}
.casting_sec ul {  padding-top: 0; }
.casting_sec ul li { margin-right: 0;  width: 100%; margin-bottom: 15px;     padding: 15px 15px;}
.casting_box { display: flex; align-items: center; }
.casting_img { width: 50px; height: 50px; margin: 0;    margin-right: 30px; }
.casting_sec ul li:before , .everyuser_sec .img-txt-row:nth-child(odd) .img-sec:after , .getstarted_sec.user_sec .img-txt-row:nth-child(odd) .img-sec:after,
.getstarted_sec.user_sec .img-txt-row:nth-child(even) .img-sec:after { display: none; }
.casting_sec {  padding-bottom: 20px; }
.features_users_head { max-width: 100%;  width: 100%;  margin-bottom: 20px; }
.features_users_slider, #features_users_slider, #features_users_slider1 {  width: 100%; padding-left: 0; }
.my_screen .study_boxslide { width: 100%; max-width: 100%; margin-left: 0; }
.user_sec .imgTxt-sec-wrap .img-txt-row:nth-child(odd) .text-section {  margin-left: 0;}
.getstarted_sec.user_sec .imgTxt-sec-wrap .img-txt-row:nth-child(even) {  margin-bottom: 0;}
.card-columns {  column-count: 2; }
.freedemo_sec .advice_txt { padding: 40px 25px 10px; margin-bottom: 25px; }
.freedemo_sec .advice_txt h3 { margin-bottom: 20px; font-size: 17px;}

.card_box { width: 100%;    min-height: auto; }
.card_box:before {  top: -56px; width: 350px;  height: 403px; }
.freedemo_sec { margin-bottom: 45px; }
.user_sec .imgTxt-sec-wrap .img-txt-row:nth-child(even) .img-sec { right: 0; }
.user_sec .imgTxt-sec-wrap .img-txt-row:nth-child(odd) .img-sec { padding: 0; }
.features_users_slider .slick-prev, #features_users_slider .splide__arrow--prev, #features_users_slider1 .splide__arrow--prev { left: 0; }
.features_users_slider .slick-next, #features_users_slider .splide__arrow--next, #features_users_slider1 .splide__arrow--next { left: auto; right: 0; }
.future_admin .features_users_slider .slick-prev { border: 1px solid #040129; }
.future_admin .features_users_slider .slick-next { border: 1px solid #040129; }
.getstarted_btm p { font-size: 15px; }
.features_users_head h4 { font-size: 17px; letter-spacing: -0.71px; line-height: 28px; }
.banner_sec .banner_button ul ul {  width: 100%; }
.footer .footer-top h5 { cursor: pointer;}
.signup_page {   padding: 80px 0px; }
p { font-size: 16px;  line-height: 28px; }
.card_box:nth-child(3n+2) { margin: 0px 0px 25px; }
.allfeatures_sec {
    margin-bottom: 40px;
}
#features_users_slider1 .splide__arrow--prev, #features_users_slider1 .splide__arrow--next{
	display: block; border: 1px solid #040129;
}
#features_users_slider1 .splide__track {
    width: 100%;
}
.features_users {
    margin-bottom: 135px;
}
.everyuser_sec .getstarted_btm {
    margin-bottom: 60px;
}
.features_users.future_admin { padding-bottom: 25px;}
}

@media (max-width: 639px) {
.footer .footer_btm .footer_btmleft ul li {  margin-right: 15px;    font-size: 14px; }
.sm_box4 { top: 175px; right: 10px;}
.sm_box5 { left: 125px;}
.sm_box2 { right: 10px; width: 140px;  height: 140px;}
.sm_box3 { width: 140px; height: 140px; left: -30px;}
.card-columns {  column-count: 1; }
.card_box.h-234, .card_box.h-203 { min-height: auto;}
.card_box:before { width: 460px; height: 306px;  right: 0;}
.allfeatures_sec {  margin-bottom: 0px; }
.advice_txt h6 {  font-size: 16px; }
.features_users { margin-bottom: 125px;}
.everyuser_sec .getstarted_btm {  margin-bottom: 60px;}
.allfeatures_sec .getstarted_head {  margin-bottom: 45px;}
.card-columns .card_box:nth-child(1) {  padding-bottom: 25px;}
.card_top img {  width: 41px; }
.card_top h4 { font-size: 17px;}
.card_txt p { font-size: 14px; line-height: 26px;}
h1, .signage_sec .banner_title h1, .my_screen .study_slidetitle h2 { font-size: 24px; line-height: 34px;}
.my_screen .study_slidetitle p {  font-size: 14px;  line-height: 24px;}
}

@media (max-width: 575px) {
.footer_btmleft { margin-bottom: 20px;}
.footer .footer_btm .footer_btmright ul {  justify-content: flex-start; margin-bottom: 21px;     flex-wrap: wrap; position: relative; }
.footer .footer_btm .footer_btmright ul li.login {  position: absolute; right: 0;  top: 5px; }
.footer_btm .row{ flex-direction: column-reverse; }
.footer_btmright h6{ display: block;  width: 100%;  font-size: 14px; margin-bottom: 5px; }
.header .header_main .navbar {  top: 79px; max-height: calc(100vh - 79px); max-height: -moz-calc(100vh - 79px);}
.footer .footer-top { margin-bottom: 30px;}
.header .container, .industry_sec .container, .container , .cust_breadcrumb_sec .container {  max-width: 100%; }
.sharing_list .sharing_col { margin-bottom: 30px; }
.sharing_box .sharing_boximg { max-width: 44px; width: 44px;}
.sharing_box .sharing_boxtxt {  width: calc(100% - 44px);}
.sharing_box .sharing_boxtxt p {  font-size: 14px;	}
.footer .footer_btm .footer_btmright { justify-content: flex-start;  display: block; position: relative;  padding-top: 30px; border-top: 1px solid #2118BC; }
.footer .footer_btm .footer_btmright ul li.login, .footer_btmright a.login { position: absolute; right: 15px;  bottom: 0; }
}

@media (max-width: 479px) {
.header .header_main .logo {  max-width: 165px; }
.nav-icon { top: 28px; right: 12px; }
.main { margin-top: 79px;}
.industry_sec .img-txt-row {  margin-bottom: 50px;}
.sharing_box .sharing_boximg { max-width: 58px;    width: 58px;}
.sharing_box .sharing_boxtxt {  width: calc(100% - 58px);}
.conect_button a.btn-border { padding: 16px 11px 14px;}
.blue_sec.blue_app .study_slider .slick-list { padding-top: 15px;}
.study_smallimg {  max-width: 100px;}
.study_smalltxt h6{ width: calc(100% - 100px)}
.sharing_box .sharing_boxtxt p { font-size: 14px; line-height: 22px; }
.tdm_txt { padding: 25px 25px;}
.reseller_formhead p {font-size: 14px; line-height:20px;}
a.btn-gradient {  font-size: 16px;  padding: 13px 18px 11px; text-align: center; }
.tdm_img {  margin-bottom: 35px;}
.reseller_form {  padding: 25px 20px;}
.reseller_form input, .reseller_form select { padding: 9px 15px; }
.reseller_form label { font-size: 15px; }
.reseller_form .form-group:nth-child(6) label { font-size: 13px; }
.select-selected:after {  top: 62px; }
.reseller_form input[type=submit] { font-size: 16px; padding: 15px 15px 14px;  text-align: center;}
.advice_txt a.btn-gradient { padding: 13px 25px 11px; }
.advice_txt h6 {  font-size: 16px; }
.responsive_tabs ul.tablist { margin-bottom: 40px;}
.study_slider .slick-dots {  bottom: 0px; }
.blue_sec {  padding-bottom: 40px;}
.countles_bg {  width: 130px;  height: 130px;}
.sm_box2 { width: 120px; height: 120px;}
.sm_box3 { left: -5px;}
.sm_box4{ width: 120px; height: 120px; top: 155px; }
.sm_box5{ width: 120px; height: 120px;  left: 40px;   bottom: -90px; }
.wpcf7-form-control .wpcf7-list-item input[type=checkbox] + span:before { width: 22px; height: 22px; }
.wpcf7-form-control .wpcf7-list-item input[type=checkbox]:checked + span:after { top: 8px; left: 9px;  width: 6px;  height: 12px; }
.signup_form form ul li a.creatbtn {  padding: 20px 20px 18px; }
.signin_page .signup_form ul li a.blue_btn, .signup_form form ul li a.blue_btn { padding: 20px 8px 18px 75px;  font-size: 16px; }
}
@media (max-width: 375px) {
.signage_sec .text-section ul li a {  width: 100%; display: block; }
.casting_txt h6 { font-size: 15px;}
.casting_img {  margin-right: 18px;}
.features_users_slider .slick-slide { margin: 0 10px; }
.featured_img1 { width: 140px; height: 107px;}
.featured_img2 { width: 120px;  height: 140px;}
.featured_img3 {  top: 175px;}
.featured_img4 {  width: 160px; height: 185px; top: 175px;}
.search-form input { width: 100%; margin-right: 0; }
.sm_box2 {  top: 130px; }
.sm_box4 {  top: 258px; }
}
@media screen and (min-width: 992px){
.header .header_main .main_menu nav ul li:hover > .sub-menu { opacity: 1;  visibility: visible; display: block !important; }
}